import type { FederatedPointerEvent } from "pixi.js";
import { BitmapText, Container, Graphics } from "pixi.js";
import type { RootDispatch, RootState, RootStore } from "~redux/store";
import type { ListenerEntry } from "@poscon/shared-frontend";
import { colorNameMap, computeColor, connectionSelector } from "@poscon/shared-frontend";
import { situationDisplayStore } from "~/situationDisplayStore";
import { dispatchMapClickEvent } from "~/customEvents";
import { startListening } from "~redux/listenerMiddleware";
import * as Sentry from "@sentry/react";
import { selectMapScale, selectRangeCenterOverride } from "./redux/slices/starsTempSlice";
import {
  selectAltLimits,
  selectBrightButtonValue,
  selectBrightState,
  selectButtonState,
  selectCharSizeButtonValue,
  selectCharSizeState,
  selectHistoryLen,
  selectLdrDir,
  selectLdrLength,
  selectPtlLength,
  StarsAltLimits,
} from "./redux/slices/starsSlice";
import {
  selectFlightplan,
  selectQuicklookedTrack,
  selectSectorTrack,
  selectTrackCoordination,
} from "./redux/slices/starsAircraftSlice";
import {
  defaultLineStyle,
  outlinedFontNameMap,
  starsFontNameMap,
  StarsFontSize,
  starsFontDimMap,
  starsTargetSymbols,
  VFR_POSITION_SYMBOL,
  getStarsBitmapTextStyles,
} from "./constants";
import { tcwColors } from "./theme";
import { starsHubConnection } from "./starsHubConnection";
import {
  CompassDirection,
  Coordinate,
  ConflictPair,
  formatBeaconCode,
  TrackId,
  Tuple,
} from "@poscon/shared-types";
import { EramFlightplan } from "@poscon/shared-types/eram";
import {
  StarsLeaderLength,
  StarsTrack,
  StarsSectorTrack,
  StarsDatablockType,
  StarsCoordination,
  StarsQuicklookedSectorTrack,
  StarsHistoryLen,
} from "@poscon/shared-types/stars";

const timesharePhaseSteps = 8;

let store: RootStore;

export const injectStore = (s: RootStore) => {
  store = s;
  const state = s.getState();
  trackManager.historyLength = selectHistoryLen(state);
  trackManager.leaderLen = selectLdrLength(state);
  trackManager.defaultLeaderDir = selectLdrDir(state);
  trackManager.ptlLen = selectPtlLength(state);
  trackManager.altitudeLimits = selectAltLimits(state);
  trackManager.updateFontAndBright(state);
};

const listeners: ListenerEntry<RootState, RootDispatch>[] = [
  {
    predicate: (action, state, prevState) => connectionSelector(state) !== connectionSelector(prevState),
    effect: () => {
      trackManager.redrawAllTracks();
    },
  },
  {
    predicate: (action, state, prevState) => state.aircraft !== prevState.aircraft,
    effect: (action, { getState }) => {
      const state = getState();
      const newTracks = state.aircraft.tracks;
      for (const trackId of trackManager.tracks.keys()) {
        if (!newTracks[trackId]) {
          trackManager.removeTrack(trackId);
        }
      }
      for (const track of Object.values(newTracks)) {
        const sectorTrack = selectSectorTrack(state, track.id);
        const quicklookedTrack = selectQuicklookedTrack(state, track.id);
        const coordinationData = selectTrackCoordination(state, track.id);
        const fp = track.fpId ? selectFlightplan(state, track.fpId) : null;
        trackManager.addOrUpdateTrack(track, sectorTrack, fp, coordinationData, quicklookedTrack);
      }
    },
  },
  {
    predicate: (action, state, prevState) =>
      selectMapScale(state) !== selectMapScale(prevState) ||
      selectRangeCenterOverride(state) !== selectRangeCenterOverride(prevState) ||
      selectButtonState(state) !== selectButtonState(prevState) ||
      selectBrightState(state) !== selectBrightState(prevState) ||
      selectCharSizeState(state) !== selectCharSizeState(prevState),
    effect: (action, { getState }) => {
      const state = getState();
      trackManager.updateFontAndBright(state);
    },
  },
  {
    predicate: (action, state, prevState) =>
      selectAltLimits(state) !== selectAltLimits(prevState) ||
      selectHistoryLen(state) !== selectHistoryLen(prevState) ||
      selectPtlLength(state) !== selectPtlLength(prevState) ||
      selectLdrLength(state) !== selectLdrLength(prevState) ||
      selectLdrDir(state) !== selectLdrDir(prevState),
    effect: (action, { getState }) => {
      const state = getState();
      trackManager.historyLength = selectHistoryLen(state);
      trackManager.leaderLen = selectLdrLength(state);
      trackManager.defaultLeaderDir = selectLdrDir(state);
      trackManager.ptlLen = selectPtlLength(state);
      trackManager.altitudeLimits = selectAltLimits(state);
      trackManager.redrawAllTracks();
    },
  },
];

listeners.forEach((listener) => startListening(listener));

export const rotationMap = {
  SW: 135,
  S: 90,
  SE: 45,
  W: 180,
  E: 0,
  NW: -135,
  N: -90,
  NE: -45,
} as const;

const baseLdrPx = 7;

function getLeaderlineStart(pos: CompassDirection): Coordinate {
  const angle = rotationMap[pos];

  const x = baseLdrPx * Math.cos((angle * Math.PI) / 180);
  const y = baseLdrPx * Math.sin((angle * Math.PI) / 180);
  return [x, y];
}

function getLeaderlineAnchorPoint(pos: CompassDirection, length: StarsLeaderLength): Coordinate {
  const angle = rotationMap[pos];

  const x = baseLdrPx * (length + 1) * Math.cos((angle * Math.PI) / 180);
  const y = baseLdrPx * (length + 1) * Math.sin((angle * Math.PI) / 180);
  return [x, y];
}

function getDbAnchorPoint(
  pos: CompassDirection,
  fontSize: StarsFontSize,
  length: StarsLeaderLength,
): Coordinate {
  const fontDim = starsFontDimMap[fontSize];
  const angle = rotationMap[pos];
  let x = baseLdrPx * (length + 1) * Math.cos((angle * Math.PI) / 180);
  const y = baseLdrPx * (length + 1) * Math.sin((angle * Math.PI) / 180) - fontDim.height / 2;

  if ((pos === "N" && length < 2) || (pos === "S" && length === 0)) {
    x += 4;
  }

  return [x, y];
}

function createFdbNodes() {
  const container = new Container();
  const alerts = Array.from({ length: 2 }, () => new BitmapText({ style: getStarsBitmapTextStyles(1) }));
  const acid = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  const alertInhibitIndicators = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  // Altitude / Scratchpad or exit gate or exit fix (field can be 4 characters based on adaptation)
  const field3 = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  const field4 = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  const field5 = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  const field6 = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  const field7 = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  const field8 = new BitmapText({ style: getStarsBitmapTextStyles(1) });

  container.addChild(...alerts, acid, alertInhibitIndicators, field3, field4, field5, field6, field7, field8);

  container.sortableChildren = true;

  return {
    container,
    alerts,
    acid,
    alertInhibitIndicators,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
  };
}

type Fdb = ReturnType<typeof createFdbNodes>;

// secondary datablock
function createSdbNodes() {
  const container = new Container();
  const eta = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  const sta = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  const speedAdvFix = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  const ias = new BitmapText({ style: getStarsBitmapTextStyles(1) });

  container.eventMode = "none";
  container.addChild(eta, sta, speedAdvFix, ias);

  container.sortableChildren = true;

  return {
    container,
    eta,
    sta,
    speedAdvFix,
    ias,
  };
}

type Sdb = ReturnType<typeof createSdbNodes>;

// partial datablock
function createPdbNodes() {
  const container = new Container();
  const alerts = Array.from({ length: 2 }, () => new BitmapText({ style: getStarsBitmapTextStyles(1) }));
  const altitude = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  const recvHandoff = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  const field3 = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  const ident = new BitmapText({ style: getStarsBitmapTextStyles(1) });

  container.eventMode = "none";
  container.addChild(...alerts, altitude, recvHandoff, field3, ident);

  container.sortableChildren = true;

  return {
    container,
    alerts,
    altitude,
    recvHandoff,
    field3,
    ident,
  };
}

type Pdb = ReturnType<typeof createPdbNodes>;

// limited datablock
function createLdbNodes() {
  const container = new Container();
  const alerts = Array.from({ length: 2 }, () => new BitmapText({ style: getStarsBitmapTextStyles(1) }));
  const beacon = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  const altitude = new BitmapText({ style: getStarsBitmapTextStyles(1) });
  const groundSpeed = new BitmapText({ style: getStarsBitmapTextStyles(1) });

  container.eventMode = "none";
  container.addChild(...alerts, beacon, altitude, groundSpeed);

  container.sortableChildren = true;

  return {
    container,
    alerts,
    beacon,
    altitude,
    groundSpeed,
  };
}

type Ldb = ReturnType<typeof createLdbNodes>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function destroyDb(db: Record<string, any>) {
  Object.values(db).forEach((node) => {
    if (node && typeof node === "object" && "destroy" in node && !node.destroyed) {
      node.destroy(true);
    }
  });
}

class TrackNode {
  ldbTint: Uint8Array | null = colorNameMap.white;

  _fdbTint: Uint8Array | null = colorNameMap.white;

  get fdbTint() {
    if (this.isReceivingHandoff && trackManager.timeSharePhase % 2) {
      return trackManager.receivingHandoffBlinkTint;
    }
    if (!this.sectorTrack.acknowledgedAfterHandoff) {
      const fdbAlpha = trackManager.fdbAlpha;
      return fdbAlpha === 0 ? null : computeColor(tcwColors.ownedDb, fdbAlpha, 0);
    }
    return this._fdbTint;
  }

  get positionSymbolAlpha() {
    if (this.isReceivingHandoff && trackManager.timeSharePhase % 2) {
      return trackManager.otherAlpha / 3;
    }
    return this.isOwned ? trackManager.posAlpha : trackManager.otherAlpha;
  }

  stcaPairs: ConflictPair[] = [];

  get isStca() {
    const pairsAreCoasting = this.stcaPairs.every((pair) => {
      const otherTrackNode = trackManager.tracks.get(pair[0] === this.track.id ? pair[1] : pair[0]);
      return otherTrackNode?.track.coasting;
    });
    return (
      this.stcaPairs.length > 0 &&
      !this.track.coasting &&
      !pairsAreCoasting &&
      (!this.sectorTrack.dbType.endsWith("LDB") ||
        this.stcaPairs.some((pair) => {
          const otherTrackNode = trackManager.tracks.get(pair[0] === this.track.id ? pair[1] : pair[0]);
          return (
            otherTrackNode &&
            !otherTrackNode.sectorTrack.dbType.endsWith("LDB") &&
            !otherTrackNode.track.coasting
          );
        }))
    );
  }

  get isReceivingHandoff() {
    return starsHubConnection.sectorId !== null && this.track.handoffReceiver === starsHubConnection.sectorId;
  }

  get isOwned() {
    return starsHubConnection.sectorId !== null && this.track.owner === starsHubConnection.sectorId;
  }

  get forceFdb() {
    return this.isOwned || this.isReceivingHandoff;
  }

  conflictDim = false;

  rootContainer = new Container();

  historyContainer = new Container();

  mainContainer = new Container();

  private _fdb: Fdb | null = null;

  get fdb() {
    if (!this._fdb) {
      this._fdb = createFdbNodes();
      this._fdb.container.onmousedown = (event) => this.onDbMouseDown(event);
    }
    return this._fdb!;
  }

  _sdb: Sdb | null = null;

  get sdb() {
    if (!this._sdb) {
      this._sdb = createSdbNodes();
    }
    return this._sdb!;
  }

  _pdb: Pdb | null = null;

  get pdb() {
    if (!this._pdb) {
      this._pdb = createPdbNodes();
    }
    return this._pdb!;
  }

  _ldb: Ldb | null = null;

  get ldb() {
    if (!this._ldb) {
      this._ldb = createLdbNodes();
    }
    return this._ldb!;
  }

  targetSymbol = new BitmapText({ style: { fontFamily: "starsTargetSymbols1", fontSize: 14 } });

  positionSymbol = new BitmapText({ style: { fontFamily: outlinedFontNameMap[1], fontSize: 14 } });

  blink = false;

  histories = Array.from(
    { length: 10 },
    () => new BitmapText({ style: { fontFamily: "starsTargetSymbols1", fontSize: 14 } }),
  ) as Tuple<BitmapText, 10>;

  graphics = new Graphics();

  track: StarsTrack;

  sectorTrack: StarsSectorTrack;

  dbType: StarsDatablockType = "LDB";

  coordinationData: StarsCoordination = {};

  fp: EramFlightplan | null = null;

  onDbMouseDown(event: FederatedPointerEvent) {
    const fpId = this.track.fpId;
    const sectorTrack = this.sectorTrack;
    if (fpId) {
      const target = event.target;
      if ("name" in target && "text" in target) {
        const flid = this.fp?.callsign ?? this.fdb.acid.text;
        event.stopImmediatePropagation();
        if (sectorTrack.dbType.endsWith("LDB")) {
          void 0;
        }
      }
    }
  }

  constructor(track: StarsTrack, container: Container) {
    this.track = track;
    this.sectorTrack = new StarsSectorTrack(track.id);

    this.rootContainer.addChild(this.mainContainer);
    this.historyContainer.zIndex = 0;
    this.rootContainer.zIndex = 10;
    this.mainContainer.zIndex = 20;
    this.graphics.zIndex = 21;

    this.rootContainer.sortableChildren = true;
    this.mainContainer.sortableChildren = true;

    const pickListener = (event: FederatedPointerEvent) => {
      event.stopImmediatePropagation();
      const sdCoordinate = [event.clientX, event.clientY] satisfies Coordinate;
      const geoCoordinate = situationDisplayStore.getLonLatFromSdCoord(sdCoordinate);
      dispatchMapClickEvent({
        button: event.button,
        targetTrackId: this.track.id,
        targetFpId: this.track.fpId ?? undefined,
        sdCoordinate,
        geoCoordinate,
      });
    };
    this.targetSymbol.onmousedown = pickListener;
    this.positionSymbol.onmousedown = pickListener;
    this.targetSymbol.eventMode = "static";
    this.positionSymbol.eventMode = "static";
    this.targetSymbol.zIndex = 11;
    this.positionSymbol.zIndex = 12;
    this.targetSymbol.position.set(-7, -7);
    for (let i = 0; i < this.histories.length; i++) {
      const hist = this.histories[i]!;
      hist.eventMode = "none";
      hist.zIndex = 10 - i;
    }

    container.addChild(this.rootContainer);
    container.addChild(this.historyContainer);
    this.rootContainer.addChild(this.graphics);
  }

  removeFdb() {
    if (this._fdb) {
      this.mainContainer.removeChild(this._fdb.container);
      destroyDb(this._fdb);
      this._fdb = null;
    }
  }

  removeLdb() {
    if (this._ldb) {
      this.mainContainer.removeChild(this._ldb.container);
      destroyDb(this._ldb);
      this._ldb = null;
    }
  }

  removePdb() {
    if (this._pdb) {
      this.mainContainer.removeChild(this._pdb.container);
      destroyDb(this._pdb);
      this._pdb = null;
    }
  }

  addFdb() {
    this.removeLdb();
    this.removePdb();
    if (!this.mainContainer.children.includes(this.fdb.container)) {
      this.mainContainer.addChild(this.fdb.container);
    }
  }

  addLdb() {
    this.removeFdb();
    this.removePdb();
    if (!this.mainContainer.children.includes(this.ldb.container)) {
      this.mainContainer.addChild(this.ldb.container);
    }
  }

  addPdb() {
    this.removeFdb();
    this.removeLdb();
    if (!this.mainContainer.children.includes(this.pdb.container)) {
      this.mainContainer.addChild(this.pdb.container);
    }
  }

  drawPdb() {
    this.rootContainer.zIndex = 2;
    this.addPdb();
    if (!this.track.target || !this.ldbTint) {
      this.mainContainer.removeChild(this.pdb.container);
      return;
    }
    const pdb = this.pdb;
    const { width: fontWidth } = starsFontDimMap[trackManager.dbSize];
    const fontStyle = getStarsBitmapTextStyles(trackManager.dbSize);

    const alt = this.track.interpolatedTrack?.modeCAltitude ?? this.track.modeCAltitude;
    pdb.altitude.text = this.track.coasting
      ? "CST"
      : alt && alt > 0
        ? (alt / 100).toFixed(0).padStart(3, "0")
        : "RDR";
    pdb.altitude.position.set(0, 0);
    pdb.altitude.tint = this.ldbTint;
    Object.assign(pdb.altitude.style, fontStyle);
    pdb.recvHandoff.position.set(fontWidth * 3, 0);
    pdb.recvHandoff.tint = this.ldbTint;
    Object.assign(pdb.recvHandoff.style, fontStyle);
    pdb.recvHandoff.text = this.track.handoffReceiverShort?.padStart(2, " ") ?? "";
    pdb.field3.position.set(fontWidth * 5, 0);
    pdb.field3.text =
      this.track.currentSquawk === 0o1200 ? "V" : this.fp?.waketurbcategory === "H" ? "H" : "";
    pdb.field3.tint = this.ldbTint;
    Object.assign(pdb.field3.style, fontStyle);

    this.drawLeader(pdb.container);
  }

  drawLdb() {
    this.rootContainer.zIndex = 1;
    this.addLdb();
    if (!this.track.target || !this.ldbTint) {
      this.mainContainer.removeChild(this.ldb.container);
      return;
    }
    const ldb = this.ldb;
    const { width: fontWidth, height: fontHeight } = starsFontDimMap[trackManager.dbSize];
    const fontStyle = getStarsBitmapTextStyles(trackManager.dbSize);
    ldb.beacon.text = formatBeaconCode(this.track.currentSquawk);
    ldb.beacon.position.set(0, 0);
    ldb.beacon.tint = this.ldbTint;
    Object.assign(ldb.beacon.style, fontStyle);
    const alt = this.track.modeCAltitude;
    ldb.altitude.text = this.track.coasting
      ? "CST"
      : alt && alt > 0
        ? (alt / 100).toFixed(0).padStart(3, "0")
        : "RDR";
    ldb.altitude.position.set(0, fontHeight);
    ldb.altitude.tint = this.ldbTint;
    Object.assign(ldb.altitude.style, fontStyle);
    ldb.groundSpeed.text = ((this.track.interpolatedTrack?.groundSpeed ?? this.track.target.groundSpeed) / 10)
      .toFixed(0)
      .padStart(2, "0");
    ldb.groundSpeed.position.set(fontWidth * 5, fontHeight);
    ldb.groundSpeed.tint = this.ldbTint;
    Object.assign(ldb.groundSpeed.style, fontStyle);

    this.drawLeader(ldb.container);
  }

  drawFdb() {
    const fp = this.fp;
    if (!fp || !this.fdbTint) {
      if (this._fdb && this.mainContainer.children.includes(this._fdb.container)) {
        this.mainContainer.removeChild(this.fdb.container);
      }
      return;
    }
    this.rootContainer.zIndex = 4;
    this.addFdb();

    const fontSize = starsFontDimMap[trackManager.dbSize];
    const fontStyle = getStarsBitmapTextStyles(trackManager.dbSize);
    const fdb = this.fdb;

    fdb.acid.text = fp.callsign;
    fdb.acid.position.set(0, 0);
    fdb.acid.tint = this.fdbTint;
    Object.assign(fdb.acid.style, fontStyle);
    fdb.field3.position.set(0, fontSize.height);
    const alt = this.track.modeCAltitude;
    const altStr = this.track.coasting ? "CST" : alt ? (alt / 100).toFixed(0).padStart(3, "0") : "RDR";
    const phase = Math.floor(trackManager.timeSharePhase / 2);
    // timeSharePhase = 2k or 2k+1
    if (phase % 2 === 0) {
      fdb.field3.text = altStr;
    } else if (phase === 1) {
      fdb.field3.text = this.coordinationData.runway ?? this.coordinationData.scratchpad1 ?? altStr;
    } else if (phase === 3) {
      fdb.field3.text = this.coordinationData.scratchpad1 ?? this.coordinationData.runway ?? altStr;
    }
    fdb.field3.tint = this.fdbTint;
    Object.assign(fdb.field3.style, fontStyle);
    const field4Text = this.track.handoffReceiverShort ?? "";
    fdb.field4.position.set(fontSize.width * 3, fontSize.height);
    fdb.field4.text = field4Text;
    fdb.field4.tint = this.fdbTint;
    Object.assign(fdb.field4.style, fontStyle);
    fdb.field5.position.set(fontSize.width * (3 + Math.max(field4Text.length, 1)), fontSize.height);
    const gs = this.track.interpolatedTrack?.groundSpeed ?? this.track.target?.groundSpeed;
    // TODO: use wake category instead of performanceCat
    fdb.field5.text = (
      (!fp.aircraftType || trackManager.timeSharePhase < 4) && gs
        ? (gs / 10).toFixed(0).padStart(2, "0")
        : fp.aircraftType
    ).padEnd(4, " ");
    fdb.field5.tint = this.fdbTint;
    Object.assign(fdb.field5.style, fontStyle);

    this.drawLeader(this.fdb.container);
  }

  drawTargetSymbol() {
    this.targetSymbol.text = starsTargetSymbols.fused;
    this.targetSymbol.tint = computeColor(tcwColors.fusedTrackSymbol, trackManager.priAlpha, 0);
    if (!this.rootContainer.children.includes(this.targetSymbol)) {
      this.rootContainer.addChild(this.targetSymbol);
    }
  }

  drawPositionSymbol() {
    const isOwned = this.isOwned;
    if (this.dbType === "FDB" || this.forceFdb || !this.sectorTrack.acknowledgedAfterHandoff) {
      this.positionSymbol.tint = computeColor(
        this.isReceivingHandoff
          ? tcwColors.posSymbHandoffAttnBlink
          : isOwned || !this.sectorTrack.acknowledgedAfterHandoff
            ? tcwColors.ownedPositionSymbol
            : tcwColors.nonFdbUnowned,
        this.positionSymbolAlpha,
        0,
      );
    } else {
      this.positionSymbol.tint = computeColor(tcwColors.nonFdbUnowned, trackManager.ldbAlpha, 0);
    }
    const text =
      this.track.ownerShort?.at(-1) ?? (this.track.currentSquawk === 0o1200 ? VFR_POSITION_SYMBOL : null);
    if (text) {
      // this.positionSymbol.text = ownerShort.length === 1 || starsHubConnection.sectorId?.at(-2) === ownerShort.at(-2) ? ownerShort.at(-1)! : ownerShort.at(-2) ?? "";
      this.positionSymbol.text = text;
      this.positionSymbol.style.fontFamily = outlinedFontNameMap[trackManager.positionSymbolSize];
      this.positionSymbol.style.fontSize = starsFontDimMap[trackManager.positionSymbolSize].height;
      const fontSize = starsFontDimMap[trackManager.positionSymbolSize];
      const pos = this.positionSymbol.position;
      const offsetX = Math.ceil((text.length * fontSize.width) / 2);
      const offsetY = Math.ceil(fontSize.height / 2);
      this.positionSymbol.position.set(pos.x - offsetX, pos.y - offsetY);
      if (!this.rootContainer.children.includes(this.positionSymbol)) {
        this.rootContainer.addChild(this.positionSymbol);
      }
    } else {
      this.rootContainer.removeChild(this.positionSymbol);
    }
  }

  drawHistory(targetHistories: StarsTrack["histories"]) {
    const end = Math.min(trackManager.historyLength, targetHistories.length);

    for (let i = 0; i < this.histories.length; i++) {
      const text = this.histories[i]!;
      if (i >= end) {
        this.historyContainer.removeChild(text);
        continue;
      }
      const { position, targetSymbol } = targetHistories[i] ?? {};
      if (!position) {
        continue;
      }
      text.tint = computeColor(
        tcwColors[`history${Math.min(i + 1, 5)}` as keyof typeof tcwColors],
        trackManager.histAlpha,
        0,
      );
      text.text = starsTargetSymbols.fusedHistory;
      const pos = situationDisplayStore.getSdCoordFromLonLat(position);
      const histPos = [Math.round(pos[0]), Math.round(pos[1])] satisfies Coordinate;
      text.position.set(histPos[0] - 7, histPos[1] - 7);
      if (!this.historyContainer.children.includes(text)) {
        this.historyContainer.addChild(text);
      }
    }
    if (
      trackManager.tracksContainer &&
      !trackManager.tracksContainer.children.includes(this.historyContainer)
    ) {
      trackManager.tracksContainer.addChild(this.historyContainer);
    }
  }

  update(
    track: StarsTrack,
    sectorTrack: StarsSectorTrack,
    fp: EramFlightplan | null,
    coordinationData: StarsCoordination,
    quicklookedTrack?: StarsQuicklookedSectorTrack,
  ) {
    this.track = track;
    this.sectorTrack = sectorTrack;
    this.fp = fp;
    this.coordinationData = coordinationData;
    const dbType = sectorTrack.dbType;
    this.dbType = this.forceFdb ? "FDB" : dbType;
    this.draw();
  }

  private drawLeader(container?: Container) {
    const leaderLen = trackManager.leaderLen;
    const leaderDir =
      this.sectorTrack.leaderDir ?? this.track.leaderDirection ?? trackManager.defaultLeaderDir;
    const leaderStart = getLeaderlineStart(leaderDir);
    const leaderEnd = getLeaderlineAnchorPoint(leaderDir, leaderLen);

    this.graphics
      .moveTo(leaderStart[0], leaderStart[1])
      .lineTo(leaderEnd[0], leaderEnd[1])
      .stroke({
        ...defaultLineStyle,
        color: computeColor(
          colorNameMap.white,
          this.forceFdb ? trackManager.fdbAlpha : trackManager.ldbAlpha,
        ),
      });

    if (container) {
      const anchor = getDbAnchorPoint(leaderDir, 1, leaderLen);
      if (leaderDir.endsWith("W") || leaderDir === "S") {
        anchor[0] -= starsFontDimMap[1].width * 8 + 8;
      }
      container.position.set(anchor[0] + 2, anchor[1]);
    }
  }

  private _draw() {
    const track = this.track;

    const { altitudeLimits } = trackManager;

    const [x, y] = situationDisplayStore.getSdCoordFromLonLat(
      track.interpolatedTrack?.position ?? track.position,
    );
    // offset for font size
    const pos = [Math.round(x), Math.round(y)] satisfies Coordinate;
    if (
      !situationDisplayStore.paddedRect.contains(x, y) ||
      this.track.coasting ||
      this.track.target?.ghosted
    ) {
      trackManager.tracksContainer?.removeChild(this.rootContainer, this.historyContainer);
      return;
    }
    this.mainContainer.position.set(pos[0], pos[1]);
    this.targetSymbol.position.set(pos[0] - 7, pos[1] - 7);
    this.positionSymbol.position.set(pos[0], pos[1]);
    this.graphics.position.set(pos[0], pos[1]);
    this.updateFontAndBright();

    this.drawTargetSymbol();
    this.drawPositionSymbol();
    this.drawHistory(track.interpolatedHistories ?? track.histories);

    this.graphics.clear();

    if (this.track.currentSquawk === 0o1200 && !this.track.owner) {
      this.drawPdb();
    } else if (this.fp) {
      if (this.dbType === "FDB" || this.forceFdb) {
        this.drawFdb();
      } else {
        this.drawPdb();
      }
    } else {
      this.drawLdb();
    }

    if (!trackManager.tracksContainer?.children.includes(this.rootContainer)) {
      trackManager.tracksContainer?.addChild(this.rootContainer);
    }
  }

  draw() {
    try {
      this._draw();
    } catch (e) {
      Sentry.captureException(e);
      const errorMsg = e instanceof Error ? e.message + "\n" + e.stack : JSON.stringify(e);
      console.error(`error drawing track ${this.track.id}, removing...\n${errorMsg}`);
      trackManager.removeTrack(this.track.id);
    }
  }

  updateFontAndBright() {
    const isOwned = this.isOwned;

    this.ldbTint =
      trackManager.ldbAlpha === 0
        ? null
        : computeColor(
            isOwned ? tcwColors.ownedDb : tcwColors.nonFdbUnowned,
            isOwned ? trackManager.fdbAlpha : trackManager.ldbAlpha,
            0,
          );
    let fdbColor = tcwColors.fdbUnowned;
    if (this.isReceivingHandoff) {
      fdbColor = tcwColors.recvHandoffAttn;
    }
    const fdbAlpha = isOwned ? trackManager.fdbAlpha : trackManager.otherAlpha;
    this._fdbTint = fdbAlpha === 0 ? null : computeColor(isOwned ? tcwColors.ownedDb : fdbColor, fdbAlpha, 0);
  }

  destroy() {
    trackManager.tracksContainer?.removeChild(this.rootContainer, this.historyContainer);
    destroyDb(this._fdb ?? {});
    destroyDb(this._pdb ?? {});
    destroyDb(this._sdb ?? {});
    destroyDb(this._ldb ?? {});
    destroyDb(this);
  }
}

// TODO: add brightness and fontsize settings
class TrackManager {
  tracksContainer?: Container;

  tracks = new Map<string, TrackNode>();

  historyLength: StarsHistoryLen = 5;

  leaderLen: StarsLeaderLength = 0;

  defaultLeaderDir: CompassDirection = "NE";

  ptlLen = 0;

  fdbAlpha = 1;

  ldbAlpha = 1;

  otherAlpha = 1;

  posAlpha = 1;

  priAlpha = 1;

  histAlpha = 1;

  positionSymbolSize: StarsFontSize = 1;

  dbSize: StarsFontSize = 1;

  get receivingHandoffBlinkTint() {
    return computeColor(tcwColors.recvHandoffAttn, this.otherAlpha / 3);
  }

  altitudeLimits: StarsAltLimits = { min: 0, max: 999 };

  timeSharePhase = 0;

  constructor() {
    situationDisplayStore.subscribe(() => {
      this.redrawAllTracks();
    });
    setInterval(() => {
      this.timeSharePhase = (this.timeSharePhase + 1) % timesharePhaseSteps;
      for (const node of this.tracks.values()) {
        node.draw();
      }
    }, 700);
  }

  updateFontAndBright(state: RootState) {
    this.fdbAlpha = selectBrightButtonValue(state, "FDB") / 100;
    this.otherAlpha = selectBrightButtonValue(state, "OTH") / 100;
    this.ldbAlpha = selectBrightButtonValue(state, "LDB") / 100;
    this.histAlpha = selectBrightButtonValue(state, "HST") / 100;
    this.posAlpha = selectBrightButtonValue(state, "POS") / 100;
    this.priAlpha = selectBrightButtonValue(state, "PRI") / 100;
    this.positionSymbolSize = selectCharSizeButtonValue(state, "CHAR_SIZE_POS");
    this.dbSize = selectCharSizeButtonValue(state, "CHAR_SIZE_DATA_BLOCKS");
    this.redrawAllTracks();
  }

  addOrUpdateTrack(
    track: StarsTrack,
    sectorTrack: StarsSectorTrack,
    fp: EramFlightplan | null,
    coordinationData: StarsCoordination,
    quicklookedTrack?: StarsQuicklookedSectorTrack,
  ) {
    if (!this.tracksContainer) {
      return;
    }
    if (!this.tracks.has(track.id)) {
      this.tracks.set(track.id, new TrackNode(track, this.tracksContainer));
    }
    const node = this.tracks.get(track.id)!;
    node.update(track, sectorTrack, fp, coordinationData, quicklookedTrack);
  }

  removeTrack(trackId: TrackId) {
    const node = this.tracks.get(trackId);
    if (node) {
      this.tracks.delete(trackId);
      node.destroy();
    }
  }

  redrawFdbTracks() {
    for (const node of this.tracks.values()) {
      if (node.dbType === "FDB") {
        node.draw();
      }
    }
  }

  updateStcaTracks(stcaPairs: ConflictPair[], suppressedPairs: ConflictPair[]) {
    for (const node of this.tracks.values()) {
      node.stcaPairs = stcaPairs.filter(
        (pair) =>
          pair.includes(node.track.id) &&
          !suppressedPairs.some((p) => p.includes(pair[0]) && p.includes(pair[1])),
      );
    }
    this.redrawAllTracks();
  }

  redrawAllTracks() {
    for (const node of this.tracks.values()) {
      node.draw();
    }
  }
}

export const trackManager = new TrackManager();
