import { getStarsBitmapTextStyles, starsFontNameMap, StarsFontSize } from "~/constants";
import { useRootSelector } from "~/redux/hooks";
import { selectCharSizeButtonValue, selectViewPosition } from "~/redux/slices/starsSlice";
import { colorNameMap, useCurrentTime } from "@poscon/shared-frontend";
import { formatUtcTime } from "@poscon/shared-types";
import { ColorSource } from "pixi.js";

type TimeProps = {
  x?: number;
  y?: number;
  fontSize: StarsFontSize;
  tint: ColorSource;
};
const Time = ({ x = 0, y = 0, fontSize, tint }: TimeProps) => {
  const time = useCurrentTime();

  // TODO: add system altimeter
  const text = formatUtcTime(time) + "/" + time.getUTCSeconds().toString().padStart(2, "0") + " ";

  return <bitmapText x={x} y={y} text={text} style={{ ...getStarsBitmapTextStyles(fontSize), fill: tint }} />;
}

export const SystemStatusArea = () => {
  const fontSize = useRootSelector((state) => selectCharSizeButtonValue(state, "CHAR_SIZE_LISTS"));
  const [x, y] = useRootSelector((state) => selectViewPosition(state, "SYSTEM_STATUS"));
  const tint = colorNameMap.green;

  return <container x={x} y={y} eventMode="none">
    <graphics eventMode="none" draw={(graphics) => {
      graphics.clear();
      graphics.rect(0, 0, 15, 15).stroke({ width: 1, color: tint });
      graphics.poly([0, 3, 14, 3, 7, 13, 0, 3]).fill(colorNameMap.red);
    }} />
    <Time y={18} fontSize={fontSize} tint={tint} />
  </container>
}