import { ArtccId } from "@poscon/shared-types";
import { PosconRole } from "@poscon/shared-types/poscon";
import { useEffect } from "react";
import { initializeConnection, starsHubConnection } from "~/starsHubConnection";

const autoSigninArtccId = import.meta.env.VITE_AUTO_SIGNIN_ARTCC_ID ?? "KZNY";
const autoSigninFacilityId = import.meta.env.VITE_AUTO_SIGNIN_FACILITY_ID ?? "N90";
const autoSigninPosition = import.meta.env.VITE_AUTO_SIGNIN_POSITION ?? "KZNY.N90.2K";

export const useAutoSignin = () => {
  useEffect(() => {
    const login = async (...args: Parameters<typeof initializeConnection>) => {
      if (starsHubConnection.sectorId === null) {
        await initializeConnection(...args);
        await starsHubConnection.connect();
      }
    };
    const urlParams = new URLSearchParams(window.location.search);
    const urlArtccId = urlParams.get("artccId");
    const urlSectorId = urlParams.get("sectorId");
    const urlFacilityId = urlParams.get("facilityId");
    const role = urlParams.get("role") ?? "Radar";
    const sectorId = urlSectorId ?? autoSigninPosition;
    const facilityId = urlFacilityId ?? sectorId?.split(".")[1] ?? autoSigninFacilityId;
    const artccId = urlArtccId ?? sectorId?.split(".")[0] ?? autoSigninArtccId;
    if (sectorId || (artccId && facilityId)) {
      void login(artccId as ArtccId, facilityId, sectorId ?? null, role as PosconRole);
    }
  }, []);
};
