import React from "react";
import { Map } from "./map/Map";
import { SituationDisplayContextProvider } from "~/contexts/sdContext";
import { DisplayControlBar } from "./displayControlBar/DisplayControlBar";
import { useWindowSize } from "usehooks-ts";
import { dcbHeight, dcbButtonWidth } from "~/buttons";
import { useRootSelector } from "~/redux/hooks";
import { selectDcbDir } from "~/redux/slices/starsSlice";
import { Compass } from "./map/Compass";
import { ClipCursorContextProvider } from "~/contexts/clipCursorContext";
import { useAutoSignin } from "~/hooks/useAutoSignin";
import { PreviewArea } from "./PreviewArea";
import { socketConnectedSelector } from "@poscon/shared-frontend";
import { FailureBanner } from "./FailureBanner";
import { selectShowDcb } from "~/redux/slices/starsTempSlice";
import { SystemStatusArea } from "./SystemStatusArea";

export const Stars = () => {
  useAutoSignin();
  const windowSize = useWindowSize();
  const dcbDir = useRootSelector(selectDcbDir);
  const socketConnected = useRootSelector(socketConnectedSelector);
  const showDcb = useRootSelector(selectShowDcb);
  return (
    <container sortableChildren>
      <ClipCursorContextProvider>
        <SituationDisplayContextProvider>
          {showDcb && <DisplayControlBar dir={dcbDir} />}
          <Compass
            x={showDcb && dcbDir === "LEFT" ? dcbButtonWidth : 0}
            y={showDcb && dcbDir === "TOP" ? dcbHeight : 0}
            width={windowSize.width - (showDcb && ["LEFT", "RIGHT"].includes(dcbDir) ? dcbButtonWidth : 0)}
            height={windowSize.height - (showDcb && ["TOP", "BOTTOM"].includes(dcbDir) ? dcbHeight : 0)}
          />
          <Map />
          {!socketConnected && <FailureBanner failureType="WORKSTATION_FAIL" />}
          <SystemStatusArea />
          <PreviewArea />
        </SituationDisplayContextProvider>
      </ClipCursorContextProvider>
    </container>
  );
};
