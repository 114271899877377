import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "~redux/store";
import { Coordinate, Nullable, nullIsland } from "@poscon/shared-types";
import { emptyStarsConfig, starsMapGroup } from "~/starsConfig";
import type { ButtonId } from "~/buttonId";
import { FeatureCollection } from "geojson";
import { StarsConfig, StarsCommandResponse } from "@poscon/shared-types/stars";

export const rangeRingSpacings = [2, 5, 10, 20] as const;
export type RangeRingSpacing = (typeof rangeRingSpacings)[number];
export type StarsVideomapId = number;
export type MapButtonIndex = number;

export type StarsTempState = {
  starsConfig: StarsConfig;
  geomaps: Record<string, FeatureCollection>;
  mapScale: number;
  offsetRangeCenter: Coordinate;
  rangeCenterOverride: Coordinate;
  rrCenter: Coordinate;
  showRangeRings: boolean;
  rrSpacing: RangeRingSpacing;
  openMaps: StarsVideomapId[];
  commandResponse: StarsCommandResponse | null;
  showDcb: boolean;
  autoDbOffset: boolean;
  activeScrollButton: Nullable<ButtonId>;
  cursorHomePosition: Nullable<Coordinate>;
};

const initialState: StarsTempState = {
  starsConfig: emptyStarsConfig,
  geomaps: {},
  mapScale: 1,
  offsetRangeCenter: [0, 0],
  rangeCenterOverride: [0, 0],
  rrCenter: [0, 0],
  showRangeRings: true,
  rrSpacing: 10,
  openMaps: [],
  commandResponse: null,
  showDcb: true,
  autoDbOffset: false,
  activeScrollButton: null,
  cursorHomePosition: null,
};

export const starsTempStateSlice = createSlice({
  name: "starsTemp",
  initialState,
  reducers: {
    setStarsConfig(state, action: PayloadAction<StarsConfig>) {
      state.starsConfig = action.payload;
      state.offsetRangeCenter = action.payload.areas[0]!.rangeCenter;
      state.rrCenter = action.payload.areas[0]!.rangeCenter;
    },
    setGeomapFeatures(state, action: PayloadAction<Record<string, FeatureCollection>>) {
      state.geomaps = action.payload;
    },
    setMapScale(state, action: PayloadAction<number>) {
      state.mapScale = action.payload;
    },
    setOffsetRangeCenter(state, action: PayloadAction<Coordinate>) {
      state.offsetRangeCenter = action.payload;
    },
    setRangeCenterOverride(state, action: PayloadAction<Coordinate>) {
      state.rangeCenterOverride = action.payload;
    },
    setRangeRingCenter(state, action: PayloadAction<Coordinate>) {
      state.rrCenter = action.payload;
    },
    setRangeRingSpacing(state, action: PayloadAction<RangeRingSpacing>) {
      state.rrSpacing = action.payload;
    },
    deltaRangeRingSpacing(state, action: PayloadAction<1 | -1>) {
      const newIndex = rangeRingSpacings.indexOf(state.rrSpacing) + action.payload;
      if (newIndex > -1 && newIndex < rangeRingSpacings.length) {
        state.rrSpacing = rangeRingSpacings[newIndex]!;
      }
    },
    setShowRangeRings(state, action: PayloadAction<boolean>) {
      state.showRangeRings = action.payload;
    },
    clearOpenMaps(state) {
      state.openMaps = [];
    },
    toggleMap(state, action: PayloadAction<MapButtonIndex>) {
      const starsId = state.starsConfig.mapGroups[starsMapGroup]?.mapIds[action.payload];
      if (starsId) {
        const index = state.openMaps.indexOf(starsId);
        if (index !== -1) {
          state.openMaps.splice(index, 1);
        } else {
          state.openMaps.push(starsId);
        }
      }
    },
    setCommandResponse(state, action: PayloadAction<StarsCommandResponse | null>) {
      state.commandResponse = action.payload;
    },
    setShowDcb(state, action: PayloadAction<boolean>) {
      state.showDcb = action.payload;
    },
    toggleShowDcb(state) {
      state.showDcb = !state.showDcb;
    },
    toggleAutoDbOffset(state) {
      state.autoDbOffset = !state.autoDbOffset;
    },
    setAutoDbOffset(state, action: PayloadAction<boolean>) {
      state.autoDbOffset = action.payload;
    },
    setActiveScrollButton(state, action: PayloadAction<ButtonId | null>) {
      state.activeScrollButton = action.payload;
    },
    toggleActiveScrollButton(state, action: PayloadAction<ButtonId>) {
      state.activeScrollButton = state.activeScrollButton === action.payload ? null : action.payload;
    },
    setCursorHomePosition(state, action: PayloadAction<Coordinate>) {
      state.cursorHomePosition = action.payload;
    },
  },
  selectors: {
    selectGeomaps: (state) => state.geomaps,
    selectGeomapFeatures: (state, id: string) => state.geomaps[id],
    selectStarsConfig: (state) => state.starsConfig,
    selectGeomapByStarsId: (state, id: number) => state.starsConfig.maps.find((v) => v.starsId === id),
    selectMapScale: (state) => state.mapScale,
    selectRangeCenter: (state) => state.starsConfig.areas[0]?.rangeCenter ?? nullIsland,
    selectOffsetRangeCenter: (state) => state.offsetRangeCenter,
    selectRangeCenterOverride: (state) => state.rangeCenterOverride,
    selectRangeRingCenter: (state) => state.rrCenter,
    selectRangeRingSpacing: (state) => state.rrSpacing,
    selectShowRangeRings: (state) => state.showRangeRings,
    selectOpenMaps: (state) => state.openMaps,
    selectCommandResponse: (state) => state.commandResponse,
    selectShowDcb: (state) => state.showDcb,
    selectAutoDbOffset: (state) => state.autoDbOffset,
    selectActiveScrollButton: (state) => state.activeScrollButton,
    selectIsActiveScrollButton: (state, buttonId: ButtonId) => state.activeScrollButton === buttonId,
    selectCursorHomePosition: (state) => state.cursorHomePosition,
  },
});

export const {
  setStarsConfig,
  setGeomapFeatures,
  setMapScale,
  setOffsetRangeCenter,
  setRangeCenterOverride,
  setRangeRingCenter,
  setRangeRingSpacing,
  deltaRangeRingSpacing,
  setShowRangeRings,
  toggleMap,
  setCommandResponse,
  setShowDcb,
  toggleShowDcb,
  toggleAutoDbOffset,
  setAutoDbOffset,
  setActiveScrollButton,
  toggleActiveScrollButton,
  setCursorHomePosition,
  clearOpenMaps,
} = starsTempStateSlice.actions;

export const starsTempReducer = starsTempStateSlice.reducer;

export const {
  selectGeomapFeatures,
  selectGeomaps,
  selectStarsConfig,
  selectGeomapByStarsId,
  selectMapScale,
  selectRangeCenter,
  selectOffsetRangeCenter,
  selectRangeCenterOverride,
  selectRangeRingCenter,
  selectRangeRingSpacing,
  selectShowRangeRings,
  selectOpenMaps,
  selectCommandResponse,
  selectShowDcb,
  selectAutoDbOffset,
  selectActiveScrollButton,
  selectIsActiveScrollButton,
  selectCursorHomePosition,
} = starsTempStateSlice.getSelectors((state: RootState) => state.starsTemp);

export const selectStarsMapButtonText = createSelector(
  [(state: RootState) => state, (state: RootState, mapIndex: MapButtonIndex) => mapIndex],
  (state, mapIndex) => {
    const starsId = selectStarsConfig(state).mapGroups[starsMapGroup]?.mapIds[mapIndex];
    if (starsId) {
      const name = selectStarsConfig(state).maps.find((m) => m.starsId === starsId)?.shortName;
      return [starsId.toString(), name ?? ""];
    }
    return [];
  },
);

export const selectStarsMapButtonSelected = (state: RootState, mapIndex: MapButtonIndex) => {
  const starsId = selectStarsConfig(state).mapGroups[starsMapGroup]?.mapIds[mapIndex];
  return typeof starsId === "number" && selectOpenMaps(state).includes(starsId);
};
