import { TextStyleOptions } from "pixi.js";

export const STARS_2K_MIN_WIDTH = 2040;
export const STARS_2K_MIN_HEIGHT = 1600;

export const defaultLineStyle = {
  width: 1,
  alpha: 1,
  color: 0xf0f0f0,
  alignment: 1,
  pixelLine: true,
};

export type StarsFontSize = 0 | 1 | 2 | 3 | 4 | 5;

export const starsFontNameMap: Record<StarsFontSize, string> = {
  // previous size zero not used for now
  // 0: "starsText0",
  0: "starsText1",
  1: "starsText2",
  2: "starsText3",
  3: "starsText4",
  4: "starsText5",
  5: "starsText6",
};

export const outlinedFontNameMap: Record<StarsFontSize, string> = {
  0: "starsText1_outlined",
  1: "starsText2_outlined",
  2: "starsText3_outlined",
  3: "starsText4_outlined",
  4: "starsText5_outlined",
  5: "starsText6_outlined",
}

export const starsFontDimMap: Record<
  StarsFontSize,
  { width: number; height: number }
> = {
  // previous size zero not used for now
  // 0: {
  //     width: 8,
  //     height: 12,
  // },
  0: {
    width: 9,
    height: 13,
  },
  1: {
    width: 10,
    height: 14,
  },
  2: {
    width: 12,
    height: 16,
  },
  3: {
    width: 14,
    height: 17,
  },
  4: {
    width: 15,
    height: 20,
  },
  5: {
    width: 18,
    height: 23,
  },
};

export const fontName = starsFontNameMap[1];
export const fontDim = starsFontDimMap[1];

export const starsTargetSymbols = {
  groundRange: `\u0810`,
  groundRangeUnfilled: `\u0811`,
  slantRange: `\u0812`,
  fused: `\u0813`,
  fma: `\u0814`,
  mlat: `\u0815`,
  fusedHistory: `\u0816`,
} as const;

export const VFR_POSITION_SYMBOL = `\u0101`;

export function getStarsBitmapTextStyles(fontSize: StarsFontSize): TextStyleOptions {
  return {
    fontFamily: starsFontNameMap[fontSize],
    fontSize: starsFontDimMap[fontSize].height,
  };
}