import React, { useLayoutEffect, useRef } from "react";
import { Container as PixiContainer } from "pixi.js";
import { RangeRings } from "./RangeRings";
import { useRootDispatch, useRootSelector } from "~/redux/hooks";
import { selectButtonState, setButtonSelectedValue } from "~/redux/slices/starsSlice";
import { useGetLonLatFromSdCoord, useMapScale } from "~/contexts/sdContext";
import { setRangeCenterOverrideToLonLat } from "~/redux/thunks/mapThunks";
import { selectRangeCenterOverride, setRangeRingCenter } from "~/redux/slices/starsTempSlice";
import { Geomaps } from "./Videomap";
import { layerZIndexMap } from "~/layerZIndexMap";
import { trackManager } from "~/trackManager";
import { usePixiMouseEventListener } from "@poscon/shared-frontend";
import { situationDisplayStore } from "~/situationDisplayStore";
import { dispatchMapClickEvent } from "~/customEvents";
import { Coordinate } from "@poscon/shared-types";
import { useApplication } from "@pixi/react";

export const Map = () => {
  const tracksContainerRef = useRef<PixiContainer>(null);
  const mapContainerRef = useRef<PixiContainer>(null);
  const scale = useMapScale();
  const centerOverride = useRootSelector(selectRangeCenterOverride);
  const dispatch = useRootDispatch();
  const { PLACE_CNTR: placeCntr, PLACE_RR: placeRr } = useRootSelector(selectButtonState);
  const getLonLatFromSdCoord = useGetLonLatFromSdCoord();
  const app = useApplication();

  usePixiMouseEventListener(
    (e) => {
      const coord = getLonLatFromSdCoord([e.pageX, e.pageY]);
      if (placeCntr) {
        dispatch(setRangeCenterOverrideToLonLat(coord, { updateOffsetCenter: true }));
        dispatch(setButtonSelectedValue({ buttonId: "PLACE_CNTR", value: false }));
        dispatch(setButtonSelectedValue({ buttonId: "OFF_CNTR", value: true }));
        e.stopImmediatePropagation();
      } else if (placeRr) {
        dispatch(setRangeRingCenter(coord));
        dispatch(setButtonSelectedValue({ buttonId: "PLACE_RR", value: false }));
        dispatch(setButtonSelectedValue({ buttonId: "RR_CNTR", value: false }));
        e.stopImmediatePropagation();
      } else {
        const sdCoordinate = [e.clientX, e.clientY] satisfies Coordinate;
        const geoCoordinate = situationDisplayStore.getLonLatFromSdCoord(sdCoordinate);
        dispatchMapClickEvent({
          button: e.button,
          sdCoordinate,
          geoCoordinate,
        });
      }
    },
    mapContainerRef,
    true,
  );

  useLayoutEffect(() => {
    if (tracksContainerRef.current) {
      trackManager.tracksContainer = tracksContainerRef.current;
    }
  }, []);

  return (
    <>
      <container ref={mapContainerRef} eventMode="static" hitArea={app.app.screen} sortableChildren>
        <container
          x={centerOverride[0]}
          y={centerOverride[1]}
          scale={scale}
          zIndex={1}
          sortableChildren
          isRenderGroup
        >
          <Geomaps />
          <RangeRings />
        </container>
      </container>
      <container
        ref={tracksContainerRef}
        zIndex={layerZIndexMap.tracks}
        label="TRACKS"
        sortableChildren
        isRenderGroup
        eventMode="static"
      />
    </>
  );
};
