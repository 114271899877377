import { Container as PixiContainer } from "pixi.js";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useWindowSize } from "usehooks-ts";
import { useRootDispatch, useRootSelector } from "~/redux/hooks";
import { DcbDir, handleDimensionChange, selectButtonState } from "~/redux/slices/starsSlice";
import {
  dcbBriteButtonPositions,
  dcbButtonWidth,
  dcbCharSizeButtonPositions,
  DcbColElement,
  dcbGiTextFilterButtonPositions,
  dcbHeight,
  dcbMainButtonPositions,
  dcbMapButtonPositions,
  dcbModeButtonPositions,
  dcbPrefButtonPositions,
  dcbShiftButtonPositions,
  dcbSiteButtonPositions,
  dcbSsaFilterButtonPositions,
  smallDcbButtonHeight
} from "~/buttons";
import { ButtonId } from "~/buttonId";
import { DcbButton } from "./DcbButton";
import { useClipCursorContext } from "~/contexts/clipCursorContext";
import { layerZIndexMap } from "~/layerZIndexMap";
import { dcb1KMainButtonPositions, dcb1KMap1ButtonPositions, dcb1KMap2ButtonPositions, dcb1KPref1ButtonPositions, dcb1KPref2ButtonPositions, dcb1KShiftButtonPositions } from "~/buttons1K";
import { STARS_2K_MIN_HEIGHT, STARS_2K_MIN_WIDTH } from "~/constants";

type ButtonListProps = {
  vert: boolean;
  startX: number;
  startY: number;
  list: readonly DcbColElement<ButtonId>[];
}
const ButtonList = ({ vert, startX, startY, list }: ButtonListProps) => {
  const ref = useRef<PixiContainer>(null);
  const { width, height } = useWindowSize();
  const [widthOverflow, setWidthOverflow] = useState(false);
  const [heightOverflow, setHeightOverflow] = useState(false);
  const { setClipRectangle } = useClipCursorContext();

  const totalWidth = vert ? dcbButtonWidth : list.reduce((acc, { width = dcbButtonWidth }) => {
    return acc + width;
  }, 0);
  const totalHeight = !vert ? dcbHeight : list.reduce((acc, { height = dcbHeight }) => {
    return acc + height;
  }, 0);

  const x = Math.max(0, !vert ? startX - (widthOverflow ? totalWidth : 0) : 0);
  const y = Math.max(0, vert ? startY - (heightOverflow ? totalHeight : 0) : 0);

  useLayoutEffect(() => {
    if (ref.current) {
      const pos = ref.current.getGlobalPosition();
      setWidthOverflow(pos.x + totalWidth > width);
      setHeightOverflow(pos.y + totalHeight > height);
    }
  }, [width, height]);

  useEffect(() => {
    if (ref.current) {
      const pos = ref.current.getGlobalPosition();
      setClipRectangle({ x: pos.x, y: pos.y, width: totalWidth, height: totalHeight });
    }
    return () => {
      setClipRectangle(null);
    }
  }, [setClipRectangle, x, y, totalWidth, totalHeight]);

  let newX = 0;
  let newY = 0;

  return (
    <container zIndex={2} ref={ref} x={x} y={y}>
      {list.map(({ elements, width = dcbButtonWidth, height: _height = dcbHeight }, i) => {
        const [buttonId, buttonId2] = elements;
        const prev = list.at(i - 1)?.elements.at(-1);
        const next = list.at(i + 1)?.elements.at(0);
        let height = _height;
        if (elements.length === 2) {
          height = smallDcbButtonHeight;
        }
        const x = vert ? 0 : newX;
        const y = vert ? newY : 0;
        newX += width;
        newY += height * elements.length;
        return (
          <React.Fragment key={buttonId}>
            <DcbButton
              key={buttonId}
              buttonId={buttonId}
              x={x}
              y={y}
              width={vert ? dcbButtonWidth : width}
              height={height}
              prevButton={prev}
              nextButton={buttonId2 ?? next}
            />
            {buttonId2 && <DcbButton
              key={buttonId2}
              buttonId={buttonId2}
              x={x}
              y={y + dcbHeight / 2}
              width={vert ? dcbButtonWidth : width}
              height={height}
              prevButton={buttonId}
              nextButton={next}
            />}
          </React.Fragment>
        );
      })}
    </container>
  );
};

type DisplayControlBarProps = {
  dir: DcbDir;
};
export const DisplayControlBar = ({ dir }: DisplayControlBarProps) => {
  const dispatch = useRootDispatch();
  const { width, height } = useWindowSize();
  const {
    SHIFT: shiftSelected,
    SSA_FILTER: ssaFilterSelected,
    BRITE: briteSelected,
    GI_TEXT: giTextFilterSelected,
    MAPS: mapsSelected,
    MAPS_1_TO_16: maps1To16Selected,
    MAPS_17_TO_32: maps17To32Selected,
    PREF: prefSelected,
    PREF_A: prefASelected,
    PREF_B: prefBSelected,
    CHAR_SIZE: charSizeSelected,
    SITE: siteSelected,
    MODE: modeSelected,
  } = useRootSelector(selectButtonState);
  const { setCursor } = useClipCursorContext();
  const vert = ["LEFT", "RIGHT"].includes(dir);

  const use1KDisplay = !vert ? width < STARS_2K_MIN_WIDTH : height < STARS_2K_MIN_HEIGHT;

  useEffect(() => {
    dispatch(handleDimensionChange());
  }, [use1KDisplay]);

  let buttonListToShow: readonly DcbColElement<ButtonId>[] = use1KDisplay ? dcb1KMainButtonPositions : dcbMainButtonPositions;
  if (shiftSelected) {
    buttonListToShow = use1KDisplay ? dcb1KShiftButtonPositions : dcbShiftButtonPositions;
  } else if (prefSelected) {
    buttonListToShow = dcbPrefButtonPositions;
  } else if (prefASelected) {
    buttonListToShow = dcb1KPref1ButtonPositions;
  } else if (prefBSelected) {
    buttonListToShow = dcb1KPref2ButtonPositions;
  }

  let buttonX = 0;
  let buttonY = 0;

  const mainListDisabled = (!shiftSelected &&
    (giTextFilterSelected || briteSelected || ssaFilterSelected || maps1To16Selected || maps17To32Selected || mapsSelected || charSizeSelected || siteSelected || modeSelected)
  );

  return (
    <container
      zIndex={layerZIndexMap.dcb}
      sortableChildren
      x={dir === "RIGHT" ? width - dcbButtonWidth : 0}
      y={dir === "BOTTOM" ? height - dcbHeight : 0}
      onMouseEnter={() => {
        setCursor("pointer");
      }}
      onMouseLeave={() => {
        setCursor("crosshair");
      }}
    >
      <graphics draw={(graphics) => {
        graphics.clear();
        switch (dir) {
          case "TOP":
          case "BOTTOM":
            graphics.rect(0, 0, width, dcbHeight).fill(0);
            break;
          case "LEFT":
          case "RIGHT":
            graphics.rect(0, 0, dcbButtonWidth, height).fill(0);
            break;
        }
      }}
      />
      {buttonListToShow.map(({ elements, width = dcbButtonWidth, height: _height = dcbHeight }, i) => {
        const [buttonId, buttonId2] = elements;

        const prev = buttonListToShow.at(i - 1)?.elements.at(-1);
        const next = buttonListToShow.at(i + 1)?.elements.at(0);
        let height = _height;
        if (elements.length === 2) {
          height = smallDcbButtonHeight;
        }
        const x = vert ? 0 : buttonX;
        const y = vert ? buttonY : 0;

        buttonX += width;
        buttonY += height * elements.length;
        return (
          <React.Fragment key={`${buttonId}-${shiftSelected}`}>
            {elements.includes("MAPS") && ssaFilterSelected && <ButtonList list={dcbSsaFilterButtonPositions} vert={vert} startX={buttonX} startY={buttonY} />}
            {elements.includes("MAPS") && mapsSelected && <ButtonList list={dcbMapButtonPositions} vert={vert} startX={buttonX} startY={buttonY} />}
            {elements.includes("MAPS_1_TO_16") && maps1To16Selected && <ButtonList list={dcb1KMap1ButtonPositions} vert={vert} startX={buttonX} startY={buttonY} />}
            {elements.includes("MAPS_17_TO_32") && maps17To32Selected && <ButtonList list={dcb1KMap2ButtonPositions} vert={vert} startX={buttonX} startY={buttonY} />}
            {elements.includes("BRITE") && briteSelected && <ButtonList list={dcbBriteButtonPositions} vert={vert} startX={buttonX} startY={buttonY} />}
            {elements.includes("GI_TEXT") && giTextFilterSelected && <ButtonList list={dcbGiTextFilterButtonPositions} vert={vert} startX={buttonX} startY={buttonY} />}
            {elements.includes("CHAR_SIZE") && charSizeSelected && <ButtonList list={dcbCharSizeButtonPositions} vert={vert} startX={buttonX} startY={buttonY} />}
            {elements.includes("SITE") && siteSelected && <ButtonList list={dcbSiteButtonPositions} vert={vert} startX={buttonX} startY={buttonY} />}
            {elements.includes("MODE") && modeSelected && <ButtonList list={dcbModeButtonPositions} vert={vert} startX={buttonX} startY={buttonY} />}
            <DcbButton
              zIndex={1}
              buttonId={buttonId}
              disabled={mainListDisabled}
              x={x}
              y={y}
              width={vert ? dcbButtonWidth : width}
              height={height}
              prevButton={prev}
              nextButton={buttonId2 ?? next}
            />
            {buttonId2 && <DcbButton
              zIndex={1}
              buttonId={buttonId2}
              disabled={mainListDisabled}
              x={x}
              y={y + dcbHeight / 2}
              width={vert ? dcbButtonWidth : width}
              height={height}
              prevButton={buttonId}
              nextButton={next}
            />}
          </React.Fragment>
        );
      })}
    </container>
  );
};
