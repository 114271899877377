import { RootState } from "../store";
import { StarsBrightButton, starsBrightButtons, StarsCharSizeButton, starsCharSizeButtons } from "~/buttons";
import { ButtonId, buttonList } from "~/buttonId";
import { CompassDirection, compassDirections, Coordinate } from "@poscon/shared-types";
import { StarsFontSize } from "~/constants";
import { RootThunkAction, setMouseSpeed } from "@poscon/shared-frontend";
import { dbPosMap } from "@poscon/shared-types/eram";
import { StarsLeaderLength, StarsHistoryLen } from "@poscon/shared-types/stars";
import { createSlice, PayloadAction, Action } from "@reduxjs/toolkit";

export type Volume = 0 | 1 | 2 | 3;
export type CursorSpeed = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export type DcbDir = "LEFT" | "TOP" | "RIGHT" | "BOTTOM";

export type StarsAltLimits = { min: number; max: number };

export const starsViews = ["PREVIEW_AREA", "SYSTEM_STATUS", "LA_CA_MCA"] as const;
export type StarsView = (typeof starsViews)[number];

export type StarsViewPositionMap = Record<StarsView, { pos: Coordinate }>;

type StarsState = {
  buttonState: Record<ButtonId, boolean>;
  brightState: Record<StarsBrightButton, number>;
  charSizeState: Record<StarsCharSizeButton, StarsFontSize>;
  ldrDir: CompassDirection;
  ldrLen: StarsLeaderLength;
  dwell: boolean;
  dcbDir: DcbDir;
  historyLen: StarsHistoryLen;
  volume: Volume;
  cursorSpeed: CursorSpeed;
  ptlLength: number;
  altLimits: StarsAltLimits;
  viewPositionMap: StarsViewPositionMap;
};

const initialState: StarsState = {
  buttonState: Object.fromEntries(buttonList.map((b) => [b, false])) as Record<ButtonId, boolean>,
  brightState: Object.fromEntries(starsBrightButtons.map((b) => [b, b === "BRITE_DCB" ? 100 : 80])) as Record<
    StarsBrightButton,
    number
  >,
  charSizeState: Object.fromEntries(starsCharSizeButtons.map((b) => [b, 1])) as Record<
    StarsCharSizeButton,
    StarsFontSize
  >,
  ldrDir: "N",
  ldrLen: 1,
  dwell: true,
  dcbDir: "TOP",
  historyLen: 5,
  volume: 2,
  cursorSpeed: 4,
  ptlLength: 1,
  altLimits: { min: 0, max: 999 },
  viewPositionMap: {
    SYSTEM_STATUS: { pos: [50, 150] },
    PREVIEW_AREA: { pos: [50, 400] },
    LA_CA_MCA: { pos: [50, 700] },
  },
};

export const starsSlice = createSlice({
  name: "stars",
  initialState,
  reducers: {
    handleDimensionChange(state) {
      state.buttonState.MAPS = false;
      state.buttonState.MAPS_1_TO_16 = false;
      state.buttonState.MAPS_17_TO_32 = false;
      state.buttonState.PREF = false;
      state.buttonState.PREF_A = false;
      state.buttonState.PREF_B = false;
    },
    setMultipleButtonSelectedValues(state, action: PayloadAction<Partial<Record<ButtonId, boolean>>>) {
      Object.assign(state.buttonState, action.payload);
    },
    setButtonSelectedValue(state, action: PayloadAction<{ buttonId: ButtonId; value: boolean }>) {
      const { buttonId, value } = action.payload;
      state.buttonState[buttonId] = value;
    },
    toggleButtonSelected(state, action: PayloadAction<ButtonId>) {
      state.buttonState[action.payload] = !state.buttonState[action.payload];
    },
    deltaBrightButton(state, action: PayloadAction<{ buttonId: StarsBrightButton; delta: number }>) {
      const { buttonId, delta } = action.payload;
      const { min, max } = buttonMinMaxMap[buttonId];
      state.brightState[buttonId] = Math.max(min, Math.min(max, state.brightState[buttonId] + 5 * delta));
    },
    setBrightButtonValue(state, action: PayloadAction<{ buttonId: StarsBrightButton; value: number }>) {
      const { buttonId, value } = action.payload;
      const { min, max } = buttonMinMaxMap[buttonId];
      state.brightState[buttonId] = Math.max(min, Math.min(max, value));
    },
    deltaCharSizeButton(state, action: PayloadAction<{ buttonId: StarsCharSizeButton; delta: number }>) {
      const { buttonId, delta } = action.payload;
      const { min, max } = buttonMinMaxMap[buttonId];
      state.charSizeState[buttonId] = Math.max(
        min,
        Math.min(max, state.charSizeState[buttonId] + delta),
      ) as StarsFontSize;
    },
    setCharSizeButtonValue(state, action: PayloadAction<{ buttonId: StarsCharSizeButton; value: number }>) {
      const { buttonId, value } = action.payload;
      const { min, max } = buttonMinMaxMap[buttonId];
      state.charSizeState[buttonId] = Math.max(min, Math.min(max, value)) as StarsFontSize;
    },
    changeLeaderDir(state, action: PayloadAction<-1 | 1>) {
      const index = compassDirections.indexOf(state.ldrDir);
      state.ldrDir = compassDirections.at((index + action.payload) % 8)!;
    },
    setLeaderDir(state, action: PayloadAction<keyof typeof dbPosMap>) {
      const direction = dbPosMap[action.payload];
      if (direction) {
        state.ldrDir = direction;
      }
    },
    deltaCursorSpeed(state, action: PayloadAction<-1 | 1>) {
      state.cursorSpeed = Math.max(
        buttonMinMaxMap.CSR_SPD.min,
        Math.min(state.cursorSpeed + action.payload, buttonMinMaxMap.CSR_SPD.max),
      ) as CursorSpeed;
    },
    setCursorSpeed(state, action: PayloadAction<number>) {
      state.cursorSpeed = Math.max(
        buttonMinMaxMap.CSR_SPD.min,
        Math.min(action.payload, buttonMinMaxMap.CSR_SPD.max),
      ) as CursorSpeed;
    },
    deltaVolume(state, action: PayloadAction<-1 | 1>) {
      state.volume = Math.max(
        buttonMinMaxMap.VOLUME.min,
        Math.min(state.volume + action.payload, buttonMinMaxMap.VOLUME.max),
      ) as Volume;
    },
    setVolume(state, action: PayloadAction<number>) {
      state.volume = Math.max(
        buttonMinMaxMap.VOLUME.min,
        Math.min(action.payload, buttonMinMaxMap.VOLUME.max),
      ) as Volume;
    },
    deltaPtlLength(state, action: PayloadAction<-1 | 1>) {
      state.ptlLength = Math.max(0, Math.min(state.ptlLength + action.payload * 0.5, 5));
    },
    deltaLdrLen(state, action: PayloadAction<-1 | 1>) {
      state.ldrLen = Math.max(
        buttonMinMaxMap.LDR_LEN.min,
        Math.min(state.ldrLen + action.payload, buttonMinMaxMap.LDR_LEN.max),
      ) as StarsLeaderLength;
    },
    setLdrLen(state, action: PayloadAction<number>) {
      state.ldrLen = Math.max(
        buttonMinMaxMap.LDR_LEN.min,
        Math.min(action.payload, buttonMinMaxMap.LDR_LEN.max),
      ) as StarsLeaderLength;
    },
    deltaHistoryLen(state, action: PayloadAction<-1 | 1>) {
      state.historyLen = Math.max(
        buttonMinMaxMap.HISTORY.min,
        Math.min(state.historyLen + action.payload, buttonMinMaxMap.HISTORY.max),
      ) as StarsHistoryLen;
    },
    setHistoryLen(state, action: PayloadAction<number>) {
      state.historyLen = Math.max(
        buttonMinMaxMap.HISTORY.min,
        Math.min(action.payload, buttonMinMaxMap.HISTORY.max),
      ) as StarsHistoryLen;
    },
    toggleDwell(state) {
      state.dwell = !state.dwell;
    },
    setDcbDir(state, action: PayloadAction<DcbDir>) {
      state.dcbDir = action.payload;
    },
    setViewPosition(state, action: PayloadAction<{ view: StarsView; pos: Coordinate }>) {
      const { view, pos } = action.payload;
      state.viewPositionMap[view] = { pos };
    },
  },
  selectors: {
    selectButtonState: (state) => state.buttonState,
    selectButtonSelected: (state, buttonId: ButtonId) => state.buttonState[buttonId],
    selectBrightState: (state) => state.brightState,
    selectBrightButtonValue: (state, buttonId: StarsBrightButton) => state.brightState[buttonId],
    selectCharSizeState: (state) => state.charSizeState,
    selectCharSizeButtonValue: (state, buttonId: StarsCharSizeButton) => state.charSizeState[buttonId],
    selectCursorSpeed: (state) => state.cursorSpeed,
    selectVolume: (state) => state.volume,
    selectPtlLength: (state) => state.ptlLength,
    selectLdrLength: (state) => state.ldrLen,
    selectLdrDir: (state) => state.ldrDir,
    selectHistoryLen: (state) => state.historyLen,
    selectDwell: (state) => state.dwell,
    selectDcbDir: (state) => state.dcbDir,
    selectAltLimits: (state) => state.altLimits,
    selectViewPositionMap: (state) => state.viewPositionMap,
    selectViewPosition: (state, view: StarsView) => state.viewPositionMap[view].pos,
  },
});

export const starsReducer = starsSlice.reducer;

export const {
  setButtonSelectedValue,
  setMultipleButtonSelectedValues,
  toggleButtonSelected,
  deltaBrightButton,
  setBrightButtonValue,
  deltaCharSizeButton,
  setCharSizeButtonValue,
  changeLeaderDir,
  setLeaderDir,
  setCursorSpeed,
  deltaVolume,
  setVolume,
  deltaPtlLength,
  deltaLdrLen,
  setLdrLen,
  deltaHistoryLen,
  setHistoryLen,
  toggleDwell,
  setDcbDir,
  setViewPosition,
  handleDimensionChange,
} = starsSlice.actions;

export const {
  selectButtonState,
  selectButtonSelected,
  selectBrightState,
  selectBrightButtonValue,
  selectCharSizeState,
  selectCharSizeButtonValue,
  selectCursorSpeed,
  selectVolume,
  selectPtlLength,
  selectLdrDir,
  selectLdrLength,
  selectHistoryLen,
  selectDwell,
  selectDcbDir,
  selectAltLimits,
  selectViewPositionMap,
  selectViewPosition,
} = starsSlice.getSelectors((state: RootState) => state.stars);

export function deltaCursorSpeedThunk(delta: 1 | -1): RootThunkAction {
  return (dispatch, getState) => {
    const newCursorSpeed = Math.max(
      buttonMinMaxMap.CSR_SPD.min,
      Math.min(selectCursorSpeed(getState()) + delta, buttonMinMaxMap.CSR_SPD.max),
    );
    setMouseSpeed(newCursorSpeed * 2 - 1);
    dispatch(setCursorSpeed(newCursorSpeed));
  };
}

export function setCursorSpeedThunk(value: number): RootThunkAction {
  return (dispatch) => {
    const newValue = Math.max(buttonMinMaxMap.CSR_SPD.min, Math.min(value, buttonMinMaxMap.CSR_SPD.max));
    setMouseSpeed(newValue * 2 - 1);
    dispatch(setCursorSpeed(newValue));
  };
}

type MinMax = {
  min: number;
  max: number;
  allowFloat?: boolean;
  setValue: (v: number) => Action<any> | RootThunkAction;
};

export const buttonMinMaxMap: Record<
  StarsBrightButton | StarsCharSizeButton | "HISTORY" | "CSR_SPD" | "VOLUME" | "LDR_LEN",
  MinMax
> = {
  WX: {
    min: 5,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "WX", value }),
  },
  BRITE_DCB: {
    min: 25,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "BRITE_DCB", value }),
  },
  BKC: {
    min: 0,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "BKC", value }),
  },
  MPA: {
    min: 5,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "MPA", value }),
  },
  MPB: {
    min: 5,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "MPB", value }),
  },
  FDB: {
    min: 0,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "FDB", value }),
  },
  LST: {
    min: 25,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "LST", value }),
  },
  POS: {
    min: 0,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "POS", value }),
  },
  LDB: {
    min: 0,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "LDB", value }),
  },
  OTH: {
    min: 0,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "OTH", value }),
  },
  TLS: {
    min: 0,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "TLS", value }),
  },
  BRITE_RR: {
    min: 0,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "BRITE_RR", value }),
  },
  CMP: {
    min: 0,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "CMP", value }),
  },
  BCN: {
    min: 0,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "BCN", value }),
  },
  PRI: {
    min: 0,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "PRI", value }),
  },
  HST: {
    min: 0,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "HST", value }),
  },
  WXC: {
    min: 5,
    max: 100,
    setValue: (value) => setBrightButtonValue({ buttonId: "WXC", value }),
  },
  CHAR_SIZE_DATA_BLOCKS: {
    min: 0,
    max: 5,
    setValue: (value) => setCharSizeButtonValue({ buttonId: "CHAR_SIZE_DATA_BLOCKS", value }),
  },
  CHAR_SIZE_LISTS: {
    min: 0,
    max: 5,
    setValue: (value) => setCharSizeButtonValue({ buttonId: "CHAR_SIZE_LISTS", value }),
  },
  CHAR_SIZE_DCB: {
    min: 0,
    max: 2,
    setValue: (value) => setCharSizeButtonValue({ buttonId: "CHAR_SIZE_DCB", value }),
  },
  CHAR_SIZE_TOOLS: {
    min: 0,
    max: 5,
    setValue: (value) => setCharSizeButtonValue({ buttonId: "CHAR_SIZE_TOOLS", value }),
  },
  CHAR_SIZE_POS: {
    min: 0,
    max: 5,
    setValue: (value) => setCharSizeButtonValue({ buttonId: "CHAR_SIZE_POS", value }),
  },
  LDR_LEN: {
    min: 0,
    max: 7,
    setValue: setLdrLen,
  },
  HISTORY: {
    min: 0,
    max: 10,
    setValue: setHistoryLen,
  },
  CSR_SPD: {
    min: 1,
    max: 10,
    setValue: setCursorSpeedThunk,
  },
  VOLUME: {
    min: 0,
    max: 3,
    setValue: setVolume,
  },
};

export function getButtonLimits(buttonId: ButtonId) {
  // @ts-ignore
  return buttonMinMaxMap[buttonId];
}
