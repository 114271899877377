import { BaseKeyboardEvent } from "@poscon/shared-frontend";
import {
  getStarsFunction,
  StarsInputToken,
  starsInputTokenOverrideMap,
  StarsKeyboardFunction,
} from "@poscon/shared-types/stars";
import { ButtonId } from "./buttonId";

const toggleButtonKeyMap: Record<string, ButtonId> = {
  SHIFT_F2: "SITE",
  SHIFT_F4: "MODE",
  SHIFT_F5: "BRITE",
  SHIFT_F6: "LDR_DIR",
  SHIFT_F8: "CHAR_SIZE",
  SHIFT_F9: "PREF",
  SHIFT_F10: "PLACE_RR",
  Home: "PLACE_CNTR",
  PageUp: "MAPS",
  PageDown: "RANGE",
};

export class StarsKeyboardEvent extends BaseKeyboardEvent {
  // string to display in input field
  token: StarsInputToken | null;

  starsFunction?: StarsKeyboardFunction;

  toggleButtonId?: ButtonId;

  private getToken() {
    const key = this.key;
    const _key = this.shift ? `SHIFT_${key}` : key;
    const overrideToken = starsInputTokenOverrideMap[_key];
    this.toggleButtonId = toggleButtonKeyMap[_key];
    this.starsFunction = getStarsFunction(_key);
    if (overrideToken) {
      return overrideToken;
    }
    return key.length === 1 ? key.toUpperCase() : null;
  }

  constructor(event: KeyboardEvent) {
    super(event);
    this.token = this.getToken();
  }
}
