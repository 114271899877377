import { situationDisplayStore } from "~/situationDisplayStore";
import {
  selectMapScale,
  selectRangeCenterOverride,
  setMapScale,
  setOffsetRangeCenter,
  setRangeCenterOverride,
} from "../slices/starsTempSlice";
import type { RootThunkAction } from "@poscon/shared-frontend";
import { createAction } from "@reduxjs/toolkit";
import type { Coordinate } from "@poscon/shared-types";
import { listenerMiddleware } from "../listenerMiddleware";
import debounce from "lodash.debounce";

type MapThunkMeta = {
  updateOffsetCenter?: boolean;
  forwarded?: boolean;
  keepRange?: boolean;
  resize?: boolean;
  keepCenter?: boolean;
};

export function setRangeCenterOverrideToLonLat(
  lonLat: Coordinate,
  meta?: MapThunkMeta,
): RootThunkAction {
  return (dispatch, getState) => {
    const mapScale = selectMapScale(getState());
    const center = [
      Math.floor(situationDisplayStore.rect.width / 2),
      Math.floor(situationDisplayStore.rect.height / 2),
    ] satisfies Coordinate;
    const newCenter = situationDisplayStore.getSdCoordFromLonLat(lonLat);
    const translate = [(newCenter[0] - center[0]) / mapScale, (newCenter[1] - center[1]) / mapScale] satisfies Coordinate;
    if (meta?.updateOffsetCenter) {
      dispatch(setOffsetRangeCenter(lonLat));
    }
    dispatch(translateRangeCenterOverride(translate, meta));
  };
}

export function translateRangeCenterOverride(translate: Coordinate, meta?: object): RootThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const rangeCenterOverride = [...selectRangeCenterOverride(state)] as Coordinate;
    const mapScale = selectMapScale(state);
    rangeCenterOverride[0] -= mapScale * translate[0];
    rangeCenterOverride[1] -= mapScale * translate[1];
    dispatch({ ...setRangeCenterOverride(rangeCenterOverride), meta });
  };
}

/**
 * the computed range might be slightly off due to the earth's curvature
 * hence we recursively compute it, upto 6 iterations
 */
function determineMapScaleRecursive({
  prevScale,
  rangeCenterOverride,
  targetRange,
  updateRangeCenterOverride = true,
  depth = 0,
}: {
  prevScale: number;
  rangeCenterOverride: Coordinate;
  targetRange: number;
  updateRangeCenterOverride?: boolean;
  depth?: number;
}) {
  const prevRange = situationDisplayStore.getRangeAtScale(
    prevScale,
    rangeCenterOverride,
  );
  const newScale = (prevScale * Math.max(1, prevRange)) /
    Math.max(targetRange, 1);

  const newRangeCenterOverride = [...rangeCenterOverride] as Coordinate;
  if (updateRangeCenterOverride) {
    const rect = situationDisplayStore.rect;
    newRangeCenterOverride[0] -= rect.width / 2;
    newRangeCenterOverride[1] -= rect.height / 2;
    newRangeCenterOverride[0] *= newScale / prevScale;
    newRangeCenterOverride[1] *= newScale / prevScale;
    newRangeCenterOverride[0] += rect.width / 2;
    newRangeCenterOverride[1] += rect.height / 2;
  }
  const computedRange = situationDisplayStore.getRangeAtScale(
    newScale,
    newRangeCenterOverride,
  );
  if (depth > 5 || computedRange === targetRange) {
    return { newScale, newRangeCenterOverride };
  }
  return determineMapScaleRecursive({
    prevScale: newScale,
    rangeCenterOverride: newRangeCenterOverride,
    depth: depth + 1,
    updateRangeCenterOverride,
    targetRange,
  });
}

export const setMapScaleToRange = (
  targetRange: number,
  updateRangeCenterOverride = true,
): RootThunkAction => {
  return (dispatch, getState) => {
    const state = getState();
    const prevScale = situationDisplayStore.matrix.a;
    const rangeCenterOverride = selectRangeCenterOverride(state);
    const { newScale, newRangeCenterOverride } = determineMapScaleRecursive({
      prevScale,
      rangeCenterOverride,
      targetRange,
      updateRangeCenterOverride,
    });
    dispatch(setRangeCenterOverride(newRangeCenterOverride));
    dispatch(setMapScale(newScale));
    if (updateRangeCenterOverride) {
      dispatch(
        setRangeCenterOverrideToLonLat(situationDisplayStore.lonLatCenter),
      );
    }
  };
};

const debouncedSetMapScaleToRange = debounce((dispatch, range) => dispatch(setMapScaleToRange(range)), 10);

export const deltaRange = (value: number): RootThunkAction => {
  return (dispatch) => {
    const currentRange = situationDisplayStore.range;
    debouncedSetMapScaleToRange(dispatch, Math.max(currentRange + value, 1));
  };
};

export const setRangeAction = createAction(
  "stars/map/setRange",
  (targetRange: number) => {
    return {
      payload: targetRange,
    };
  },
);

export const resetRangeAction = createAction("stars/map/resetRange");

export const setRangeCenterOverrideToLonLatAction = createAction(
  "stars/map/rangeToLonLat",
  (lonLat: Coordinate) => {
    return {
      payload: lonLat,
    };
  },
);

listenerMiddleware.startListening({
  predicate: (action) => resetRangeAction.match(action),
  effect: (_, { dispatch }) => {
    dispatch(setMapScale(1));
    dispatch(setRangeCenterOverride([0, 0]));
  },
});
