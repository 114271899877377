import { Coordinate, FlightplanId, TrackId } from "@poscon/shared-types";
import { ButtonId } from "./buttonId";
import { StarsInputElement } from "@poscon/shared-types/stars";

export type MapClickEvent = CustomEvent<{
  geoCoordinate: Coordinate;
  sdCoordinate: Coordinate;
  button: MouseEvent["button"];
  targetTrackId?: TrackId;
  targetFpId?: FlightplanId;
}>;

export function dispatchMapClickEvent(detail: MapClickEvent["detail"]) {
  document.dispatchEvent(new CustomEvent("mapclick", { detail }));
}

export function dispatchSetInputEvent(detail: StarsInputElement[]) {
  document.dispatchEvent(new CustomEvent("setInput", { detail }));
}

export function dispatchResetInputEvent() {
  dispatchSetInputEvent([]);
}

export function dispatchFocusButtonEvent(buttonId: ButtonId) {
  document.dispatchEvent(new CustomEvent("focusButton", { detail: buttonId }));
}
