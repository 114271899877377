import * as Sentry from "@sentry/react";
import { env } from "./env";
import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import { PosconStage } from "@poscon/shared-frontend";
import { store } from "~redux/store";
import { Provider } from "react-redux";
import { Stars } from "./components/Stars";
import { Assets } from "pixi.js";
import 'pixi.js/text-bitmap';

Sentry.init({
  dsn: env.VITE_SENTRY_URL_STARS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  sampleRate: 1,
  // Tracing
  tracesSampleRate: 0.001,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["hq.poscon.local", "https://services.poscon.com/eram"],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 0.1,
});

await Assets.load([
  new URL("/fonts/starsTargetSymbols1.xml", import.meta.url).href,
  new URL("/fonts/starsText0.xml", import.meta.url).href,
  new URL("/fonts/starsText1.xml", import.meta.url).href,
  new URL("/fonts/starsText1_outlined.xml", import.meta.url).href,
  new URL("/fonts/starsText2.xml", import.meta.url).href,
  new URL("/fonts/starsText2_outlined.xml", import.meta.url).href,
  new URL("/fonts/starsText3.xml", import.meta.url).href,
  new URL("/fonts/starsText3_outlined.xml", import.meta.url).href,
  new URL("/fonts/starsText4.xml", import.meta.url).href,
  new URL("/fonts/starsText4_outlined.xml", import.meta.url).href,
  new URL("/fonts/starsText5.xml", import.meta.url).href,
  new URL("/fonts/starsText5_outlined.xml", import.meta.url).href,
  new URL("/fonts/starsText6.xml", import.meta.url).href,
  new URL("/fonts/starsText6_outlined.xml", import.meta.url).href,
]);

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <div id="eramRoot" className="root" onContextMenu={(e) => e.preventDefault()}>
      <PosconStage>
        <Provider store={store}>
          <Stars />
        </Provider>
      </PosconStage>
    </div>
  </React.StrictMode>,
);
