import {
  setAutoDbOffset,
  setCommandResponse,
  setCursorHomePosition,
  toggleAutoDbOffset,
} from "../redux/slices/starsTempSlice";
import { createAction, Dispatch } from "@reduxjs/toolkit";
import { dispatchResetInputEvent, dispatchSetInputEvent } from "../customEvents";
import { setViewPosition, StarsView, toggleButtonSelected } from "../redux/slices/starsSlice";
import { HasAtLeast, includes } from "@poscon/eram-cpdlc";
import { hasExactly } from "@poscon/shared-types";
import { StarsInputElement, starsFormatError } from "@poscon/shared-types/stars";

const moveViewMap: Record<string, StarsView> = {
  P: "PREVIEW_AREA",
  S: "SYSTEM_STATUS",
};

export const resetCommandInputAction = createAction("stars/previewAreaInput/reset");

/**
 *
 * @param dispatch
 * @param param1 command
 * @returns true if command has been processed, false if command needs to be processed on the server
 */
export function processMultifuncCommand(
  dispatch: Dispatch,
  [command1, ...command]: HasAtLeast<StarsInputElement, 1>,
) {
  if (command.length === 0) {
    dispatch(setCommandResponse(starsFormatError()));
    return true;
  }
  // toggle/enable/disable auto datablock offset
  if (command[0]?.token?.startsWith("O")) {
    const [_, ...args] = command[0].token.split("");
    if (args.length === 0) {
      dispatch(toggleAutoDbOffset());
      dispatchResetInputEvent();
      return true;
    }
    if (args.length === 1 && includes(["I", "E"], args[0])) {
      dispatch(setAutoDbOffset(args[0] === "E"));
      dispatchResetInputEvent();
      return true;
    }
    dispatch(setCommandResponse(starsFormatError()));
    return true;
  }
  // move stars list/view
  const viewToMove = command[0]?.token ? moveViewMap[command[0].token] : null;
  if (viewToMove) {
    if (hasExactly(command, 2) && command[1]?.sdCoordinate) {
      dispatch(setViewPosition({ view: viewToMove, pos: command[1].sdCoordinate }));
      dispatchSetInputEvent([command1, command[0]]);
      return true;
    }
    dispatch(setCommandResponse(starsFormatError()));
    return true;
  }
  // re-define cursor home position
  if (command.length === 2 && command[0]!.token === "INC" && command[1]?.sdCoordinate) {
    dispatch(setCursorHomePosition(command[1].sdCoordinate));
    dispatchResetInputEvent();
    return true;
  }
  // enable auto home cursor positioning
  if (command.length === 1 && command[0]!.token === "IHS") {
    dispatch(toggleButtonSelected("CURSOR_HOME"));
    return true;
  }
  return false;
}
