import { StarsConfig } from "@poscon/shared-types/stars";

export const emptyStarsConfig: StarsConfig = {
  areas: [],
  internalAirports: [],
  beaconCodeBanks: [],
  rpcs: [],
  primaryScratchpadRules: [],
  secondaryScratchpadRules: [],
  rnavPatterns: [],
  allow4CharacterScratchpad: false,
  starsHandoffIds: [],
  videoMapIds: [],
  mapGroups: [],
  artccId: "KZNY",
  facilityId: "",
  artsId: "",
  maps: [],
};

export const starsMapGroup =
  parseInt(new URLSearchParams(document.location.search).get("mapGroup") ?? "1") - 1;
