import {
  InflatedStarsClientEventMap,
  StarsCommand,
  StarsCommandProcessingError,
  StarsCommandResponse,
  StarsConfig,
  StarsFacilityId,
  starsGenericResponse,
  StarsServerEventMap,
} from "@poscon/shared-types/stars";
import {
  checkPosconDir,
  posconApi,
  resetAircraftState,
  setConnection,
  setSocketConnected,
} from "@poscon/shared-frontend";
import { Manager, Socket } from "socket.io-client";
import type { DefaultEventsMap } from "@socket.io/component-emitter";
import { starsSlice } from "./redux/slices/starsSlice";
import { RootStore } from "./redux/store";
import { env } from "./env";
import { inflate } from "pako";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import {
  deleteFlightplans,
  deleteSectorTracks,
  deleteTracks,
  setFlightplans,
  setSectorTracks,
  setTracks,
  updateCoordination,
  updateFlightplans,
  updateInterpolatedTracks,
} from "./redux/slices/starsAircraftSlice";
import {
  interpolatedTrackQueueRef,
  interpolateTrackUpdates,
  numTrackUpdateInterpolations,
  targetUpdatePublishInterval,
} from "./interpolateTargets";
import { setGeomapFeatures, setStarsConfig } from "./redux/slices/starsTempSlice";
import { isAction } from "@reduxjs/toolkit";
import { ArtccId, Nullable } from "@poscon/shared-types";
import { ControllerSectorId, PosconRole } from "@poscon/shared-types/poscon";

const syncedUiSlices = [starsSlice.name] as const;
type SyncedUiSlice = (typeof syncedUiSlices)[number];

let store: RootStore;
export const ERAM_SERVER_URL = env.VITE_ERAM_SERVER_URL;

type IncomingEventsMap = InflatedStarsClientEventMap & DefaultEventsMap;

export class StarsHubConnection {
  private manager: Manager<IncomingEventsMap, StarsServerEventMap> | null = null;

  private _socket: Socket<IncomingEventsMap, StarsServerEventMap> | null = null;

  get socket() {
    return this._socket;
  }

  set socket(sock) {
    this._socket = sock;
  }

  private artccId: ArtccId | null = null;

  private facilityId: StarsFacilityId | null = null;

  private _sectorId: ControllerSectorId | null = null;

  get sectorId() {
    return this._sectorId;
  }

  async getValue<T>(filename: string, endpoint: string, signal?: AbortSignal) {
    const response = await fetch(`${ERAM_SERVER_URL}/${endpoint}`, {
      signal,
    });
    const data = (await response.json()) as T;
    return data;
  }

  async getStarsConfig(facilityId: StarsFacilityId, signal?: AbortSignal) {
    return this.getValue<StarsConfig>("starsConfig", `starsConfig/${facilityId}`, signal);
  }

  isActive = false;

  async initialize(
    artccId: ArtccId,
    facilityId: StarsFacilityId,
    socketUrl: string,
    sectorId: ControllerSectorId | null,
    role: PosconRole,
  ) {
    this.artccId = artccId;
    this.facilityId = facilityId;

    await checkPosconDir();
    if (this.manager === null) {
      const manager = new Manager<IncomingEventsMap, StarsServerEventMap>(socketUrl, {
        transports: ["websocket", "webtransport"],
        query: {
          client: "STARS",
          role,
          sectorId,
        },
        path: "/eram/socket.io",
        reconnection: true,
        reconnectionAttempts: 12,
        reconnectionDelay: 5e3,
        autoConnect: false,
      });
      this.manager = manager;
      const socket = manager.socket(`/stars.${facilityId}`, {
        auth: { token: await posconApi.getAtcAccessToken() },
      });
      socket.onAny((event, ...args) => {
        if (this.eventHandlers[event]) {
          const eventHandlerArgs = args.map((arg) =>
            arg instanceof Uint8Array || arg instanceof ArrayBuffer
              ? JSON.parse(inflate(arg, { to: "string" }))
              : arg,
          );
          // @ts-ignore
          this.eventHandlers[event]?.(...eventHandlerArgs);
        }
      });
      this.socket = socket;
      this.socket.on("connect_error", (err) => {
        // the reason of the error, for example "xhr poll error"
        console.log("stars hub connection error", err.message);

        if ("description" in err) {
          // some additional description, for example the status code of the initial HTTP response
          console.log(err.description);
        }
        if ("context" in err) {
          // some additional context, for example the XMLHttpRequest object
          console.log(err.context);
        }
      });
      this.socket.on("connect", () => {
        console.log(`connected with transport ${this.socket?.io.engine.transport.name}`);
        store.dispatch(setSocketConnected(true));
      });
      this.socket.on("disconnect", (reason, details) => {
        // the reason of the disconnection, for example "transport error"
        console.log("stars hub disconnected ", reason);

        if (details) {
          // the low-level reason of the disconnection, for example "xhr post error"
          console.log("message" in details ? details.message : "");

          // some additional description, for example the status code of the HTTP response
          console.log("description" in details ? details.description : "");

          // some additional context, for example the XMLHttpRequest object
          console.log("context" in details ? details.context : "");
        }
        store.dispatch(setSocketConnected(false));
      });
      this.getStarsConfig(facilityId).then(async (config) => {
        store.dispatch(setStarsConfig(config));
        // TODO: create geomap store, cache on disk
        const geomaps = Object.fromEntries(
          await Promise.all(
            config.videoMapIds.map(async (id) => {
              const res = await fetch(
                `https://cdn.poscon.com/videomaps/stars/${config.facilityId}/${id}.geojson`,
              );
              const geomap = await res.json();
              return [id, geomap];
            }),
          ),
        );
        store.dispatch(setGeomapFeatures(geomaps));
      });
    }
  }

  emit<K extends keyof StarsServerEventMap>(event: K, ...args: Parameters<StarsServerEventMap[K]>) {
    this.socket?.emit(event, ...args);
  }

  targetUpdateIntervalId: Nullable<ReturnType<typeof window.setInterval>> = null;

  onStoreInit(store: RootStore) {
    this.registerEventHandler("receiveStarsUiAction", (action) => {
      if (isAction(action)) {
        store.dispatch({ ...action, meta: { forwarded: true } });
      }
    });
    this.registerEventHandler("receiveConnectionStatus", (connection) => {
      this._sectorId = connection.sectorId;
      this.isActive = connection.isActive;
      store.dispatch(setConnection(connection));
    });
    this.registerEventHandler("updateTracks", (tracks) => {
      store.dispatch(setTracks(tracks));
    });
    this.registerEventHandler("targetUpdate", (tracks) => {
      store.dispatch(setTracks(Object.values(tracks)));
      interpolateTrackUpdates(tracks);
    });
    this.registerEventHandler("deleteTracks", (trackIds) => {
      store.dispatch(deleteTracks(trackIds));
    });
    this.registerEventHandler("removeSectorTracks", (trackIds) => {
      store.dispatch(deleteSectorTracks(trackIds));
    });
    this.registerEventHandler("addFlightplans", (flightplans) => {
      store.dispatch(setFlightplans(flightplans));
    });
    this.registerEventHandler("updateFlightplans", (flightplans) => {
      store.dispatch(updateFlightplans(flightplans));
    });
    this.registerEventHandler("deleteFlightplans", (ids) => {
      store.dispatch(deleteFlightplans(ids));
    });
    this.registerEventHandler("updateCoordination", (coordination) => {
      store.dispatch(updateCoordination(coordination));
    });
    this.registerEventHandler("updateSectorTracks", (sectorTracks) => {
      store.dispatch(setSectorTracks(sectorTracks));
    });

    this.targetUpdateIntervalId = setInterval(() => {
      // console.log(interpolatedTrackQueueRef.reports.length);
      const report = interpolatedTrackQueueRef.reports.shift();
      if (interpolatedTrackQueueRef.reports.length > numTrackUpdateInterpolations + 1) {
        interpolatedTrackQueueRef.reports = interpolatedTrackQueueRef.reports.slice(
          -(numTrackUpdateInterpolations + 1),
        );
      }
      if (report) {
        store.dispatch(updateInterpolatedTracks(report));
      }
    }, targetUpdatePublishInterval);
  }

  private eventHandlers: {
    [K in keyof IncomingEventsMap]?: (...args: Parameters<IncomingEventsMap[K]>) => unknown;
  } = {};

  registerEventHandler<K extends keyof IncomingEventsMap>(
    event: K,
    callback: (...args: Parameters<IncomingEventsMap[K]>) => unknown,
  ) {
    this.eventHandlers[event] = callback;
  }

  async signin() {
    const socket = this.socket;
    if (socket?.connected) {
      console.log(`signing in`);
      return new Promise<void>((resolve, reject) => {
        socket.emit("signin", (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve();
        });
      });
    }
    return Promise.reject("NOT_CONNECTED");
  }

  async signout() {
    const socket = this.socket;
    // TODO: return promise with callback from emit event
    if (socket?.connected) {
      console.log(`signing out`);
      this._sectorId = null;
      // if (this.client === "ERAM") {
      //   replayManager.stopRecording();
      // }
      return new Promise<void>((resolve, reject) => {
        socket.emit("signout", (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve();
        });
      });
    }
  }

  async connect() {
    return new Promise<void>((resolve, reject) => {
      if (this.socket) {
        if (this.socket.connected) {
          resolve();
          return;
        }
        this.socket.once("connect", () => {
          resolve();
        });
        this.socket.connect();
      } else {
        reject("NO_SOCKET");
      }
    });
  }

  reset() {
    this._sectorId = null;
    store?.dispatch(resetAircraftState());
    store?.dispatch(setConnection(null));
  }

  disconnect() {
    this.socket?.disconnect();
    this.reset();
  }

  processStarsCommand(command: StarsCommand): Promise<StarsCommandResponse> {
    return new Promise((resolve, reject) => {
      let rejected = false;
      setTimeout(() => {
        reject(new StarsCommandProcessingError(starsGenericResponse("INTERNAL SW ERROR")));
        rejected = true;
      }, 10e3);
      this.emit("processStarsCommand", command, (result) => {
        if (!rejected) {
          resolve(result);
        }
      });
    });
  }
}

export const starsHubConnection = new StarsHubConnection();

export async function initializeConnection(
  artccId: ArtccId,
  facilityId: StarsFacilityId,
  sectorId: ControllerSectorId | null = null,
  role: PosconRole = "Radar",
) {
  await starsHubConnection.initialize(artccId, facilityId, ERAM_SERVER_URL, sectorId, role);
}

export const injectStore = async (s: RootStore) => {
  store = s;
  starsHubConnection.onStoreInit(store);
};

if (window.__TAURI__) {
  const webviewWindow = WebviewWindow.getCurrent();
  await webviewWindow.listen("poscon:close", async () => {
    if (starsHubConnection.isActive) {
      try {
        await starsHubConnection.signout();
      } catch (err) {
        console.error(err);
      }
    }
    void webviewWindow.close();
  });
}
