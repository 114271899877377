import { colorNameMap, useCustomEventListener } from "@poscon/shared-frontend";
import { MULTIFUNC, StarsCommand, StarsInputElement } from "@poscon/shared-types/stars";
import React, { useEffect, useState } from "react";
import { useEventListener } from "usehooks-ts";
import { MapClickEvent } from "~/customEvents";
import { processStarsCommand } from "~/commands/processStarsCommand";
import { useRootDispatch, useRootSelector } from "~/redux/hooks";
import {
  selectCharSizeButtonValue,
  selectViewPosition,
  setMultipleButtonSelectedValues,
  toggleButtonSelected,
} from "~/redux/slices/starsSlice";
import {
  selectActiveScrollButton,
  selectCommandResponse,
  setCommandResponse,
  setShowDcb,
  toggleShowDcb,
} from "~/redux/slices/starsTempSlice";
import { StarsKeyboardEvent } from "~/starsKeyboardEvent";
import { starsFontNameMap, starsFontDimMap, getStarsBitmapTextStyles } from "~/constants";

function formatStarsCommand(command: StarsCommand) {
  const s = command.reduce<string>((acc, elem, i) => {
    let next = acc;
    next += elem.token ?? "";
    if (
      (command.at(i - 1)?.starsFunction === MULTIFUNC && elem.token) ||
      (elem.starsFunction && elem.starsFunction.length > 1)
    ) {
      next += " ";
    }
    return next;
  }, "");
  return s.split(" ");
}

export const PreviewArea = () => {
  const dispatch = useRootDispatch();
  const fontSize = useRootSelector((state) => selectCharSizeButtonValue(state, "CHAR_SIZE_LISTS"));
  const [x, y] = useRootSelector((state) => selectViewPosition(state, "PREVIEW_AREA"));
  const response = useRootSelector(selectCommandResponse);
  const [input, setInput] = useState<StarsInputElement[]>([]);
  const activeScrollButton = useRootSelector(selectActiveScrollButton);

  const fontName = starsFontNameMap[fontSize];
  const fontDim = starsFontDimMap[fontSize];

  useEffect(() => {
    if (activeScrollButton) {
      setInput([]);
    }
  }, [activeScrollButton]);

  useCustomEventListener<StarsInputElement[]>("setInput", ({ detail }) => {
    setInput(detail);
  });

  useEventListener("keydown", (e) => {
    if ((e.code === "KeyI" || e.code === "KeyR") && e.ctrlKey && e.shiftKey) {
      // open devtools
      return;
    }
    e.preventDefault();
    const event = new StarsKeyboardEvent(e);
    if (event.esc) {
      setInput([]);
      dispatch(setCommandResponse(null));
      return;
    }
    if (event.rightShift) {
      dispatch(toggleButtonSelected("SHIFT"));
      return;
    }
    // TODO: handle "button press" keyboard commands, such as RR, CHAR SIZE etc
    switch (event.key) {
      case "Backspace":
        setInput((prev) => prev.slice(0, -1));
        return;
      case "End":
        dispatch(toggleShowDcb());
        return;
      case "Enter":
        dispatch(processStarsCommand(input));
        return;
    }
    if (event.toggleButtonId) {
      dispatch(setShowDcb(true));
      dispatch(setMultipleButtonSelectedValues({ SHIFT: false, [event.toggleButtonId]: true }));
      return;
    }
    if (event.starsFunction) {
      setInput([{ token: event.token ?? "", key: event.key, starsFunction: event.starsFunction }]);
      return;
    }
    if (event.token !== null) {
      setInput((prev) => [
        ...prev,
        { token: event.token ?? "", key: event.key, starsFunction: event.starsFunction },
      ]);
      return;
    }
  });

  useCustomEventListener("mapclick", (event: MapClickEvent) => {
    const { button, ...rest } = event.detail;
    setInput((prev) => [...prev, rest]);
    dispatch(processStarsCommand([...input, rest]));
  });

  const text = [response?.extrinsicReadout ?? ""];
  if (response) {
    text.push(...response.readout);
  }
  text.push(...formatStarsCommand(input));

  const tint = colorNameMap.green;

  return (
    <container x={x} y={y} zIndex={999}>
      {text.map((s, i) => {
        return (
          <bitmapText
            key={i}
            y={i * fontDim.height}
            text={s}
            style={{ ...getStarsBitmapTextStyles(fontSize), fill: tint }}
          />
        );
      })}
    </container>
  );
};
