import React from "react";
import { useWindowSize } from "usehooks-ts";
import { dcbHeight } from "~/buttons";
import {
  defaultLineStyle,
  starsFontNameMap,
  starsFontDimMap,
  getStarsBitmapTextStyles,
} from "~/constants";

const fontName = starsFontNameMap[3];
const fontDim = starsFontDimMap[3];
const failureBannerHeight = fontDim.height + 6;

const tint = 0xadadad;

export type FailureType =
  | "WORKSTATION_FAIL"
  | "SYSTEM_ESL_FAIL"
  | "DIRECT_SENSOR_ACCESS_MODE_FAIL"
  | "DIRECT_SENSOR_FEED_MODE_FAIL"
  | "AMZ_FAIL"
  | "SURV_SENSOR_FAIL"
  | "SERVICE_LVL_NOT_AVAIL";
export type SensorId = string;

const failureTextMap: Record<FailureType, string> = {
  WORKSTATION_FAIL: "WORKSTATION FAILURE",
  SYSTEM_ESL_FAIL: "SYSTEM FAILURE - SELECT ESL",
  DIRECT_SENSOR_ACCESS_MODE_FAIL: "DIRECT SENSOR ACCESS MODE FAILURE",
  DIRECT_SENSOR_FEED_MODE_FAIL: "DIRECT SENSOR FEED MODE FAILURE",
  AMZ_FAIL: "AMZ FAILURE",
  SURV_SENSOR_FAIL: "SURVEILLANCE SENSOR FAIL - SENSOR ",
  SERVICE_LVL_NOT_AVAIL: "SERVICE LEVEL NOT AVAILABLE",
};

type FailureBannerProps = {
  x?: number;
  y?: number;
  sensorIdFail?: SensorId;
  failureType: FailureType;
};

export const FailureBanner = (
  { x, y = dcbHeight + 50, sensorIdFail, failureType }: FailureBannerProps,
) => {
  const windowSize = useWindowSize();
  const text = failureTextMap[failureType] + (sensorIdFail ?? "");
  const width = (text.length + 6) * fontDim.width;
  return (
    <container x={x ?? Math.floor(windowSize.width / 2)} y={y} eventMode="none">
      <graphics
        draw={(graphics) => {
          graphics
            .rect(
              -width / 2,
              0,
              width,
              failureBannerHeight,
            )
            .fill(0)
            .stroke({ ...defaultLineStyle, color: tint });
        }}
      />
      <bitmapText
        x={-Math.floor(text.length * fontDim.width / 2)}
        y={3}
        text={text}
        tint={tint}
        style={{ ...getStarsBitmapTextStyles(3), fill: tint }}
      />
    </container>
  );
};
